<div class="onboarding-container">
  @if (parkingSession) {
    <div class="text-center mb-6">
      <div class="flex flex-col gap-4 headline-x-small">
        @if (parkingSession.is_cleared) {
          <i class="icon fa-solid fa-circle-arrow-up text-success-dark"></i>
          <span [innerText]="'SignUp.YouCanExitTheLocationNow' | translate"></span>
        } @else {
          <i class="icon fa-solid fa-circle-arrow-up"></i>
          <span [innerText]="'SignUp.YourAccountHasBeenActivatedSuccessfully' | translate"></span>
        }
      </div>
      <arivo-license-plate
        class="block mx-auto my-4"
        [country]="parkingSession.license_plate.license_plate_country"
        [licensePlate]="parkingSession.license_plate.license_plate"
      ></arivo-license-plate>

      @if (!parkingSession.is_cleared) {
        <div class="body-large">
          <span class="text-error" [innerText]="'SignUp.PleasePayOpenAmountBeforeExit' | translate"></span>
        </div>
      } @else {
        <div class="body-large">
          <span class="text-error" [innerText]="'SignUp.PleaseDontPayTicket' | translate"></span>
        </div>
      }

      <div class="well flex flex-col gap-2 my-6">
        <div class="flex">
          <div class="flex-grow body-medium text-neutral-50 text-left" [innerText]="'Onboarding.Start' | translate"></div>
          <div
            class="flex-grow body-medium text-neutral-10 text-right"
            [innerText]="parkingSession.entry_time | date: 'dd/MM/yy hh:mm'"
          ></div>
        </div>

        <div class="flex">
          <div class="flex-grow body-medium text-neutral-50 text-left" [innerText]="'Onboarding.Duration' | translate"></div>
          <div
            class="flex-grow body-medium text-neutral-10 text-right"
            [innerHtml]="parkingSession.duration_in_seconds | duration: 'seconds'"
          ></div>
        </div>

        @if (parkingSession.amount_unpaid.amount !== '0') {
          <div class="flex">
            <div class="flex-grow body-medium text-neutral-50 text-left">
              @if (parkingSession.is_cleared) {
                <strong [innerText]="'SignUp.TransferredAmount' | translate"></strong>
              } @else {
                <strong [innerText]="'Onboarding.OpenAmount' | translate"></strong>
              }
            </div>
            <div class="flex-grow body-medium text-neutral-10 text-right">
              <strong [innerText]="parkingSession.amount_unpaid.amount | localizedCurrency: parkingSession.amount_unpaid.currency"></strong>
            </div>
          </div>
        }
      </div>

      @if (parkingSession.is_cleared) {
        <div class="body-medium" [innerText]="'SignUp.PleaseAddPaymentMethodBeforeNextPaymentSession' | translate"></div>
      } @else {
        <div class="body-medium" [innerText]="'SignUp.ParkingSessionCouldNotBeTransferred' | translate"></div>
      }
    </div>
  }
  <div class="flex justify-center">
    <button class="button-primary" type="button" [innerText]="'SignUp.CompleteProfileNow' | translate" (click)="completeProfile()"></button>
  </div>
</div>
