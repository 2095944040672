import { Component, DestroyRef, inject, Inject, OnInit, ViewChild } from '@angular/core';
import { ArivoPaymentMethod, Money } from '../../../shared/models/common';
import { ActivatedRoute } from '@angular/router';
import { TypedRoute } from '../../../shared/utils/router.utils';
import { DashboardResolve } from '../../../shared/models/routeTyping';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ArivoPaymentContainerComponent } from '../../../shared/components/arivo-payment-container/arivo-payment-container.component';
import { ArivoPaymentService } from '../../../shared/components/arivo-payment-container/arivo-payment.service';
import { CoreDataService } from '../../../core/services/core-data.service';
import { FeatureFlagService } from '../../../core/services/feature-flag.service';

@Component({
  selector: 'app-dashboard-account-balance',
  templateUrl: './dashboard-account-balance.component.html',
  styleUrl: './dashboard-account-balance.component.scss',
})
export class DashboardAccountBalanceComponent implements OnInit {
  @ViewChild('paymentContainer') paymentContainer!: ArivoPaymentContainerComponent;
  private destroyRef = inject(DestroyRef);
  creditCardIconClass: string | null = null;
  openCosts: Money[] = [];
  paymentMethod: ArivoPaymentMethod | null = null;
  adyenIframeEnabled: boolean = false;

  constructor(
    @Inject(ActivatedRoute) private route: TypedRoute<DashboardResolve>,
    private paymentService: ArivoPaymentService,
    private coreDataService: CoreDataService,
    private featureFlagService: FeatureFlagService,
  ) {}

  ngOnInit(): void {
    this.adyenIframeEnabled = this.featureFlagService.getFeatureFlag('enable_iframe_adyen');

    this.route.data.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data) => {
      this.openCosts = data.dashboardApiResponse.open_costs;
      this.paymentMethod = data.dashboardApiResponse.payment_method;
      this.coreDataService.setPaymentMethodAvailability(!!this.paymentMethod);
      if (this.paymentMethod?.brand) {
        this.creditCardIconClass = this.paymentService.getCreditCardIcon(this.paymentMethod.brand);
      }
    });
    this.paymentService.activePaymentMethod.subscribe((paymentMethod) => {
      if (paymentMethod) {
        this.paymentMethod = paymentMethod;
        if (this.paymentMethod?.brand) {
          this.creditCardIconClass = this.paymentService.getCreditCardIcon(this.paymentMethod.brand);
        }
      }
    });
  }
}
