import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, switchMap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StorageManagerService } from '../services/storage-manager.service';
import * as Sentry from '@sentry/angular';
import { CoreDataService } from '../services/core-data.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private storageManagerService: StorageManagerService,
    private coreDataService: CoreDataService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Exclude requests to local assets
    if (request.url.startsWith('assets') || request.url.startsWith('https://wipark.azurefd.net/v1/marketingagreement')) {
      return next.handle(request);
    }

    let headers: { [key: string]: string } = {};

    try {
      const token = this.storageManagerService.load('token');
      if (token) {
        headers['Authorization'] = `Token ${token}`;
      }
    } catch (err) {
      Sentry.captureException(err);
    }

    try {
      headers['Accept-Language'] = this.storageManagerService.load('language') ?? 'en';
    } catch (err) {
      Sentry.captureException(err);
    }

    request = request.clone({
      setHeaders: headers,
    });

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          (error.status === 401 || error.status === 403) &&
          !(request.url.includes('/api/user/v2/auth/logout/') || request.url.includes('/api/backoffice/v2/customer_portal/core/'))
        ) {
          return this.coreDataService.fetchCoreData().pipe(switchMap(() => throwError(() => error)));
        }
        return throwError(() => error);
      }),
    );
  }
}
