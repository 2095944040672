import { Component, DestroyRef, Inject, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DashboardResolve } from '../../shared/models/routeTyping';
import { TypedRoute } from '../../shared/utils/router.utils';
import { ParkingSession } from '../../shared/models/parkingSession';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent {
  private destroyRef = inject(DestroyRef);
  openParkingSessions: ParkingSession[] = [];

  constructor(@Inject(ActivatedRoute) private route: TypedRoute<DashboardResolve>) {
    this.route.data.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data) => {
      this.openParkingSessions = data.dashboardApiResponse.open_parking_sessions;
    });
  }
}
