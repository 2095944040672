import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {
  ImageOption,
  ProviderContactData,
  ProviderGeneralData,
  ProviderImageType,
  ProviderLinkOptions,
  ProviderSettings,
  ProviderSettingsApiResponse,
} from '../../shared/models/providerSettings';
import { defaultProviderSettings } from '../../shared/data/defaultProviderSettings.data';

@Injectable({
  providedIn: 'root',
})
export class ProviderSettingsService {
  providerSettings: BehaviorSubject<ProviderSettings> = new BehaviorSubject<ProviderSettings>(defaultProviderSettings);

  constructor(private http: HttpClient) {}

  fetchProviderSettings(): Observable<ProviderSettings> {
    return this.http
      .get<ProviderSettingsApiResponse>('/api/backoffice/v2/customer_portal/provider_data/')
      .pipe(map((x) => this.deserializeProviderSettingsApiResponse(x)));
  }

  setProviderSettings(settings: ProviderSettings) {
    this.providerSettings.next(settings);
  }

  getContactData(): ProviderContactData {
    let contactData: ProviderContactData = {
      email: this.providerSettings.value.general_data.contact_email,
      phone: this.providerSettings.value.general_data.contact_phone_number,
    };
    if (this.providerSettings.value.general_data.contact_website) {
      contactData.website = this.providerSettings.value.general_data.contact_website;
    }
    return contactData;
  }

  getLinks(): ProviderLinkOptions {
    return this.providerSettings.value.links;
  }

  getProviderGeneralData(): ProviderGeneralData {
    return this.providerSettings.value.general_data;
  }

  getTranslationKey() {
    return this.providerSettings.value.feature_flags.translation_key;
  }

  setTranslationKey(translationKey: string) {
    this.providerSettings.value.feature_flags.translation_key = translationKey;
  }

  getProviderImage(imageType: ProviderImageType): ImageOption | undefined {
    //Fallback to logo if no large logo is available
    if (!this.providerSettings.value.images[imageType]) {
      if (imageType === 'logo_large') {
        imageType = 'logo';
      }
    }
    return this.providerSettings.value.images[imageType];
  }

  deserializeProviderSettingsApiResponse(response: ProviderSettingsApiResponse): ProviderSettings {
    const deserializedResponse: ProviderSettings = this.initializeDeserializedResponse(response);

    this.updateLinks(deserializedResponse.links, response.links);
    this.updateImages(deserializedResponse.images, response.images);
    this.updateFeatureFlags(deserializedResponse.feature_flags, response.feature_flags);

    return deserializedResponse;
  }

  private initializeDeserializedResponse(response: ProviderSettingsApiResponse): ProviderSettings {
    return {
      website_title: response.website_title ?? defaultProviderSettings.website_title,
      general_data: {
        ...defaultProviderSettings.general_data,
        ...Object.fromEntries(Object.entries(response.general_data).filter(([_, value]) => value !== '')),
      },
      // general_data: response.general_data ?? defaultProviderSettings.general_data,
      links: { ...defaultProviderSettings.links },
      images: { ...defaultProviderSettings.images },
      feature_flags: { ...defaultProviderSettings.feature_flags },
    };
  }

  private updateLinks(target: ProviderSettings['links'], source: ProviderSettingsApiResponse['links']) {
    for (const key in source) {
      target[key as keyof ProviderSettings['links']] = source[key];
    }
  }

  private updateImages(target: ProviderSettings['images'], source: ProviderSettingsApiResponse['images']) {
    for (const key in source) {
      if (Object.hasOwn(target, key)) {
        target[key as keyof ProviderSettings['images']] = source[key];
      }
    }
  }

  private updateFeatureFlags(target: ProviderSettings['feature_flags'], source: ProviderSettingsApiResponse['feature_flags']) {
    for (const key in source) {
      if (Object.hasOwn(target, key)) {
        target[key as keyof ProviderSettings['feature_flags']] = source[key];
      }
    }
  }
}
