<div class="card-responsive mb-4">
  <div class="card-header">
    <div class="headline-x-small">
      <i class="max-md:text-2xl fa-kit fa-data"></i>
      <span [innerText]="'SignUp.ProvidePaymentMethod' | translate"></span>
    </div>
  </div>
  @if (!!paymentMethod) {
    <div class="card-body flex justify-center mb-10 md:mb-0 items-center">
      <div class="cta-large mr-10" [innerText]="'Onboarding.StoredPaymentMethod' | translate"></div>
      <div class="">
        @switch (paymentMethod.type) {
          @case ('scheme') {
            <div class="flex items-center gap-3 w-full h-6 md:h-8">
              <div class="headline-large mb-1">
                <i [ngClass]="creditCardIconClass"></i>
              </div>
              <div class="whitespace-nowrap flex items-center">
                <div class="mr-1 text-neutral-70">****</div>
                <div>{{ paymentMethod.card_summary }}</div>
              </div>
            </div>
          }
          @case ('googlepay') {
            <div class="flex items-center gap-3 w-full h-6 md:h-8">
              <i class="headline-large fa-brands fa-google-pay"></i>
            </div>
          }
          @case ('applepay') {
            <div class="flex items-center gap-3 w-full h-6 md:h-8">
              <i class="headline-large fa-brands fa-apple-pay"></i>
            </div>
          }
          @case ('sepadirectdebit') {
            <div class="flex items-center gap-3 w-full h-6 md:h-8">
              <i class="headline-large fa-light fa-money-bill-transfer"></i>
              <p class="truncate" dir="rtl">SEPA</p>
            </div>
          }
        }
      </div>
    </div>
  }
  @if (!showErrorComponent) {
    <div class="card-body">
      @if (!adyenIframeEnabled) {
        <arivo-payment-container
          [autoInit]="true"
          [remountOnError]="true"
          (submitted)="next()"
          (setupFailed)="showErrorComponent = true"
        ></arivo-payment-container>
      } @else {
        <app-arivo-payment-iframe
          [autoInit]="true"
          [remountOnError]="true"
          (submitted)="next()"
          (setupFailed)="showErrorComponent = true"
        ></app-arivo-payment-iframe>
      }
    </div>
  } @else {
    <div class="card-body">
      <div class="text-center my-6">
        <div class="text-error text-4xl">
          <i class="fa-light fa-exclamation-triangle"></i>
        </div>
        <div class="body-large mt-2">
          <span class="text-error" [innerText]="'Common.Errors.AdyenNotWorking' | translate"></span>
        </div>
      </div>
    </div>
  }
</div>

<arivo-step-footer>
  <button
    class="button-primary"
    type="submit"
    [innerText]="'Common.Skip' | translate"
    (click)="next()"
    *ngIf="showNextButton || showErrorComponent"
  ></button>
</arivo-step-footer>
