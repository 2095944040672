import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SignupParkingSession } from '../../../shared/models/parkingSession';
import { OnboardingService } from '../onboarding/onboarding.service';
import { LPRCountryCode } from '../../../shared/models/country';
import { showSpinner } from '../../../shared/utils/common.utils';
import { FeatureFlagService } from '../../../core/services/feature-flag.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-onboarding-finish',
  templateUrl: './onboarding-finish.component.html',
  styleUrl: './onboarding-finish.component.scss',
})
export class OnboardingFinishComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  parkingSession: SignupParkingSession | null = null;

  showParkingSessionOverview: boolean = true;
  appAndNotificationsEnabled!: boolean;

  constructor(
    private route: ActivatedRoute,
    private onboardingService: OnboardingService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.route.queryParamMap.subscribe((queryParams) => {
      const licensePlate = queryParams.get('license_plate');
      const licensePlateCountry = queryParams.get('license_plate_country');

      if (licensePlate && licensePlateCountry) {
        this.onboardingService
          .getOnboardingSession({
            license_plate: licensePlate,
            license_plate_country: licensePlateCountry as LPRCountryCode,
          })
          .pipe(showSpinner())
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((data) => {
            this.parkingSession = data;

            if (!this.parkingSession) {
              this.showParkingSessionOverview = false;
            }
          });
      } else {
        this.showParkingSessionOverview = false;
      }
    });
  }

  ngOnInit() {
    this.appAndNotificationsEnabled = this.featureFlagService.getFeatureFlag('enable_app_and_notifications');
  }
}
