import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { ToastService } from '../../services/toast.service';
import { LoadingService } from '../../../core/services/loading.service';
import { DialogService } from '../../services/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../core/services/language.service';
import * as Sentry from '@sentry/angular';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { StorageManagerService } from '../../../core/services/storage-manager.service';
import { showSpinner } from '../../utils/common.utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AdditionalDetailsData, ResultCode } from '@adyen/adyen-web';
import { ArivoPaymentService } from '../arivo-payment-container/arivo-payment.service';
import { ArivoPaymentMethod } from '../../models/common';

interface AdyenMessageEvent extends MessageEvent {
  data: {
    status: string;
    height?: number;
    paymentMethod?: ArivoPaymentMethod;
    navigationTarget?: {
      url: string;
      method: string;
      data?: any;
    };
    error?: string;
  };
}

@Component({
  selector: 'app-arivo-payment-iframe',
  templateUrl: './arivo-payment-iframe.component.html',
  styleUrl: './arivo-payment-iframe.component.scss',
})
export class ArivoPaymentIframeComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() autoInit = false;
  @Input() remountOnError = false;
  @Output() submitted = new EventEmitter<void>();
  @Output() failed = new EventEmitter<void>();
  @Output() initialized = new EventEmitter<void>();
  @Output() setupFailed = new EventEmitter<void>();
  // @ViewChild('dropInHook', { static: true }) dropInHook: ElementRef = new ElementRef('');
  @ViewChild('adyenIFrame') adyenIFrame!: ElementRef<HTMLIFrameElement>;
  // @ViewChild('divider') divider!: ElementRef<HTMLDivElement>;

  // result from redirecting
  redirectResult?: string;
  iFrameUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.adyen_iframe_url);

  // private dropInComponent: any;
  private _destroyRef = inject(DestroyRef);

  private messageEventListener = this.handleMessageEvent.bind(this);

  constructor(
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private router: Router,
    private paymentService: ArivoPaymentService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private languageService: LanguageService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private storageManagerService: StorageManagerService,
  ) {}

  ngOnInit() {
    this.redirectResult = this.route.snapshot.queryParams['redirectResult'];
    if (this.redirectResult) {
      this.onAdditionalDetails({ data: { details: { redirectResult: this.redirectResult } } });
    }
  }

  ngAfterViewInit() {
    window.addEventListener('message', this.messageEventListener);
    if (this.autoInit && !this.redirectResult) {
      this.setUpComponents();
    }
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.messageEventListener);
  }

  setUpComponents() {
    this.loadingService.setLoading(true);
    this.sendSetupMessageToIFrame();
  }

  onAdditionalDetails(state: AdditionalDetailsData) {
    this.ngZone.run(() => {
      this.paymentService
        .submitAdditionalDetails(state)
        .pipe(showSpinner())
        .pipe(takeUntilDestroyed(this._destroyRef))
        .subscribe({
          next: (result: any) => {
            if (!result.resultCode) {
              return;
            }
            let successResultCodes: ResultCode[] = ['Authorised', 'Received'];
            if (successResultCodes.includes(result.resultCode)) {
              this.toastService.showSuccess(this.translateService.instant('Payment.Success'));
              this.paymentService.setActivePaymentMethod({
                type: result.paymentMethod.type,
                brand: result.paymentMethod.brand,
                card_summary: result.additionalData.cardSummary,
              });
              this.clearRedirectResult().then(() => {
                this.submitted.emit();
              });
            } else {
              this.dialogService.showPaymentMethodDepositFailedDialog();
              this.clearRedirectResult().then(() => {
                if (this.remountOnError) this.sendSetupMessageToIFrame();
              });
            }
          },
          error: () => {
            this.clearRedirectResult().then(() => {
              if (this.remountOnError) this.sendSetupMessageToIFrame();
            });
            this.toastService.showError(this.translateService.instant('Payment.Errors.UnknownError'));
          },
        });
    });
  }

  unmountComponents() {
    setTimeout(() => {
      try {
        this.sendMessageToIFrame({ type: 'unmountComponents' });
      } catch (error) {
        // ignore error, even if we put all checks in place that dropInComponent is not null,
        // it still throws an "can not read properties of 'null'" error
        // so we just try, accept it might fail and move on, since if it fails, the component is already unmounted
      }
    }, 150);
  }

  clearRedirectResult() {
    this.redirectResult = undefined;
    return this.router.navigate([], { queryParams: { redirectResult: null }, replaceUrl: true });
  }

  //IFRAME

  private handleMessageEvent(event: AdyenMessageEvent) {
    if (!event.origin.startsWith(environment.adyen_iframe_url)) {
      return;
    }

    const { status, height, paymentMethod, navigationTarget, error } = event.data;

    if (status) {
      switch (status) {
        case 'ready':
          if (this.autoInit && !this.redirectResult) {
            this.sendSetupMessageToIFrame();
          }
          break;
        case 'initialized':
          this.loadingService.setLoading(false);
          this.initialized.emit();
          break;
        case 'submitted':
          if (paymentMethod) this.handleIFrameSubmissionSuccess(paymentMethod);
          break;
        case 'failed':
          this.failed.emit();
          break;
        case 'setupFailed':
          this.handleSetupFailure();
          break;
        case 'heightChange':
          if (height) this.setIframeHeight(height);
          break;
        case 'navigate':
          if (navigationTarget) this.handleRedirectMessage(navigationTarget.url, navigationTarget.method, navigationTarget.data);
          break;
        case 'error':
          Sentry.captureException({ type: 'IFrame Error', error: error });
          if (this.remountOnError) this.sendSetupMessageToIFrame();
          break;
        default:
          if (!environment.production) console.error('Unknown status:', status);
      }
    }
  }

  private handleIFrameSubmissionSuccess(paymentMethod: ArivoPaymentMethod) {
    setTimeout(() => {
      this.submitted.emit();
    }, 2500);
    this.toastService.showSuccess(this.translateService.instant('Payment.Success'));
    this.paymentService.setActivePaymentMethod({
      type: paymentMethod.type,
      brand: paymentMethod.brand,
      card_summary: paymentMethod.card_summary,
    });
  }

  private handleSetupFailure() {
    this.loadingService.setLoading(false);
    this.setupFailed.emit();
    this.toastService.showError(this.translateService.instant('Common.Errors.AdyenNotWorking'));
  }

  setIframeHeight(height: number) {
    const iframe = this.adyenIFrame.nativeElement;
    iframe.style.height = `${height}px`;
  }

  sendSetupMessageToIFrame() {
    this.sendMessageToIFrame({
      type: 'setupComponents',
      returnUrl: this.router.url,
      token: this.storageManagerService.load('token'),
      language: this.storageManagerService.load('language') ?? 'en',
    });
  }

  sendMessageToIFrame(message: { type: string; [key: string]: any }) {
    this.adyenIFrame.nativeElement.contentWindow?.postMessage(message, environment.adyen_iframe_url);
  }

  handleRedirectMessage(url: string, method: string, data: any) {
    if (method === 'POST') {
      const form = document.createElement('form');
      form.method = 'post';
      form.action = url;
      form.style.display = 'none';

      Object.keys(data).forEach((key) => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = data[key];
        form.appendChild(input);
      });

      document.body.appendChild(form);
      form.submit();
    } else {
      window.location.assign(url);
    }
  }
}
