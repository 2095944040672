import { Component, DestroyRef, inject, Inject, ViewChild } from '@angular/core';
import { Vehicle } from '../../shared/models/licensePlate';
import { ArivoDialogComponent } from '../../shared/components/ui-components/arivo-dialog/arivo-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { TypedRoute } from '../../shared/utils/router.utils';
import { VehiclesResolve } from '../../shared/models/routeTyping';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { VehiclesApiService } from './vehicles-api.service';
import { ToastService } from '../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { formatLicensePlate } from '../../shared/utils/license-plate.utils';
import { showSpinner } from '../../shared/utils/common.utils';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrl: './vehicles.component.scss',
})
export class VehiclesComponent {
  private destroyRef = inject(DestroyRef);

  vehicles: Vehicle[] = [];

  @ViewChild('deleteVehicleDialog') deleteVehicleDialog!: ArivoDialogComponent;
  deleteVehicleItem?: Vehicle;

  constructor(
    private toastService: ToastService,
    private translateService: TranslateService,
    private vehiclesApiService: VehiclesApiService,
    @Inject(ActivatedRoute) private route: TypedRoute<VehiclesResolve>,
  ) {
    this.route.data.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data) => {
      this.vehicles = data.vehiclesApiResponse;
    });
  }

  openDeleteVehicleDialog(event: MouseEvent, vehicle: Vehicle): void {
    event.stopPropagation();
    if (vehicle.garage_contracts.length === 0) {
      this.deleteVehicleItem = vehicle;
      this.deleteVehicleDialog.open = true;
    }
  }

  closeDeleteVehicleDialog(): void {
    this.deleteVehicleItem = undefined;
    this.deleteVehicleDialog.open = false;
  }

  deleteVehicle(): void {
    const vehicle = this.deleteVehicleItem;
    if (vehicle) {
      this.deleteVehicleItem = undefined;
      this.deleteVehicleDialog.open = false;

      this.vehiclesApiService
        .deleteVehicle(vehicle.id!)
        .pipe(showSpinner())
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: () => {
            this.vehicles = this.vehicles.filter((item) => item.id !== vehicle.id);

            this.toastService.add({
              message: this.translateService.instant('Vehicles.DeleteVehicleSuccess', {
                licensePlate: formatLicensePlate(vehicle.license_plate, vehicle.license_plate_country),
              }),
              type: 'success',
            });
          },
        });
    }
  }
}
