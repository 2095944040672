import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { LoadingService } from './core/services/loading.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  loading: boolean = false;

  constructor(private loadingService: LoadingService) {}

  ngOnInit() {
    this.loadingService
      .loadingChanges()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((loading) => {
        setTimeout(() => {
          this.loading = loading;
        }, 0);
      });
  }
}
