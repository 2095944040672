<div class="card">
  <div class="card-header">
    <div class="headline-large">Datenschutzerklärungen</div>
    <div class="headline-x-small">Version 1 (01.04.2025)</div>
  </div>
  <div class="card-body">
    <div class="body-medium space-y-1">
      <div class="cursor-pointer hover:text-interaction-light text-interaction" (click)="scrollToHeader('portal-dsgvo-header')">
        <i class="fa fal fa-arrow-up-right-from-square mr-2"></i>
        Datenschutzerklärung Arivo Customer Portal
      </div>
      <div class="cursor-pointer hover:text-interaction-light text-interaction" (click)="scrollToHeader('garage-dsgvo-header')">
        <i class="fa fal fa-arrow-up-right-from-square mr-2"></i>Datenschutzerklärung Parkflächen, Parkgaragen, Parkhäuser und Parkplätze
      </div>
    </div>
  </div>

  <div class="card-body">
    <div class="headline-small mb-5" id="portal-dsgvo-header">Datenschutzerklärung Arivo Customer Portal</div>
    <div class="body-medium">
      <ol>
        <li class="mb-3 font-bold text-lg">
          <p>Verantwortliche Stelle</p>
          <div class="body-medium">
            <ul class="my-3">
              <li>{{ providerData.name }}</li>
              <li>{{ providerData.address }}</li>
              <li>{{ providerData.zip_code }}, {{ providerData.city }}</li>
              <li>{{ providerData.country }}</li>
              <li *ngIf="providerData.contact_phone_number">
                <a
                  [href]="'tel:' + providerData.contact_phone_number"
                  class="hover:text-interaction-light text-interaction no-underline"
                  [innerText]="providerData.contact_phone_number"
                ></a>
              </li>
              <li *ngIf="providerData.contact_email">
                <a
                  [href]="'mailto:' + providerData.contact_email"
                  class="hover:text-interaction-light text-interaction no-underline"
                  [innerText]="providerData.contact_email"
                ></a>
              </li>
            </ul>
          </div>
        </li>
        <li class="font-bold text-lg mb-3">Erhobene Daten und Verwendungszweck</li>
        <ol class="font-bold">
          <li>
            <div>Arivo Customer Portal für Dauerparker und Parken nach Zeit</div>
            <div class="body-medium">
              Folgende personenbezogenen Daten werden verarbeitet:
              <ul class="my-3">
                <li>E-Mail-Adresse</li>
                <li>Auto-Kennzeichen</li>
                <li>Adresse</li>
                <li>Angaben zum Abrechnungskonto / Zahlungsmittel (SEPA, Kreditkarte, Google Pay, Apple Pay)</li>
                <li>
                  Parktransaktionsdaten (Einfahrtzeit, Ausfahrzeit, Standort, Parkdatum, Dauer des Parkvorgangs, Kosten des Parkvorgangs)
                </li>
              </ul>
              Wir verarbeiten Ihre personenbezogenen Daten für den Zweck der Registrierung und Erstellung des Kontos, zur Nutzung des Arivo
              Customer Portals und Abwicklung des Vertrages auf der Grundlage des Art 6 Abs 1 lit b DSGVO (zur Vertragserfüllung) basierend
              auf den Nutzungsbedingungen des Arivo Customer Portals. Stellen Sie uns die zuvor genannten personenbezogenen Daten nicht
              bereit, ist eine Diensterbringung nicht möglich. Es besteht somit eine vertragliche Pflicht zur Bereitstellung der
              personenbezogenen Daten, weil wir ohne eine solche Bereitstellung wir den mit Ihnen betreffend die Nutzung des Arivo Customer
              Portals geschlossenen Vertrag nicht erfüllen können.
            </div>
          </li>
          <li>
            <div>Zustellung von Push-Nachrichten</div>
            <div class="body-medium">
              Sie haben die Möglichkeit, im Arivo Customer Portal die Zustellung von Push-Nachrichten zu aktiven Parkvorgängen, Ihrem
              Vertrag und zu Information über Verrechnungen zu aktivieren. Zu diesem Zweck verarbeiten wir folgende weitere personenbezogene
              Daten:
              <ul class="my-3">
                <li>Geräte-ID</li>
                <li>Einwilligung</li>
              </ul>
              Wir verarbeiten Ihre personenbezogenen Daten für den Zweck der Zustellung von Push-Nachrichten auf Grundlage Ihrer erteilten
              Einwilligung gemäß Art 6 Abs 1 lit a DSGVO. Sie können diese Einwilligung jederzeit widerrufen oder die Funktion direkt im
              Arivo Customer Portal deaktivieren. Der Widerruf bzw. die Deaktivierung hat zur Folge, dass Sie keine Push-Nachrichten mehr
              erhalten. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit, der aufgrund der Einwilligung bis zum Widerruf
              erfolgten Verarbeitung nicht berührt.
            </div>
          </li>

          <li>
            <div>Aufzeichnungen bei der Nutzung des Arivo Customer Portals</div>
            <div class="body-medium">
              Für die Bereitstellung des Arivo Customer Portals verarbeiten wir folgende personenbezogene Daten:
              <ul class="my-3">
                <li>IP Adresse Ihres Geräts</li>
                <li>Datum und Uhrzeit der letzten Anmeldung und Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
                <li>Zugriffsstatus/http-Statuscode</li>
                <li>übertragene Datenmenge</li>
                <li>Gerätetyp</li>
                <li>Betriebssystem</li>
                <li>Informationen zu Browsertyp und -version</li>
                <li>E-Mail-Adresse</li>
                <li>Session Token</li>
              </ul>
              Eine Standorterkennung findet nur mit Ihrer Einwilligung gemäß Art 6 Abs 1 lit a DSGVO statt, wenn sie dieser unter “Standort
              & Verträge“ aktiv zustimmen. Sie können Ihre Einwilligung jederzeit widerrufen. Durch den Widerruf der Einwilligung wird die
              Rechtmäßigkeit, der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.
            </div>
            <div class="body-medium">
              Die Daten erheben und verarbeiten wir auf Basis unseren berechtigten Interesses gemäß Art 6 Abs 1 lit f DSGVO, um zu
              gewährleisten, dass unser Arivo Customer Portal sicher und funktionstüchtig bleibt und das Arivo Customer Portal zur Verfügung
              gestellt werden kann. Die Daten werden für die Dauer von 3 Monaten gespeichert oder solange dies zur Verfolgung oder Abwehr
              von Rechtsansprüchen erforderlich ist.
            </div>
          </li>
          <li>
            <div>E-Mail-Benachrichtigung</div>
            <div class="body-medium">
              Wir senden Ihnen wichtige Informationen zur von Ihnen gebuchten Leistung per E-Mail zu. Diese Mitteilungen sind erforderlich,
              um die gebuchte Leistung ordnungsgemäß bereitzustellen und enthalten keine Werbung. Eine Abmeldung von diesen E-Mails ist
              nicht möglich, da sie essenzieller Bestandteil der Vertragsabwicklung sind.
            </div>
          </li>
          <li>
            <div>Cookies bei der Nutzung des Arivo Customer Portals</div>
            <div class="body-medium">
              Für das Arivo Customer Portal werden nur technisch notwendige Cookies eingesetzt, um die Funktionen des Portals zu
              gewährleisten. Die dabei gesammelten Informationen werden nach dem Verlassen des Portals automatisch gelöscht. Wir verwenden
              keine Werbe-Cookies.
            </div>
          </li>
        </ol>
        <li class="font-bold text-lg mb-3">
          Erhobene Daten und Verwendungszweck
          <div class="body-medium">
            Zur technischen Umsetzung werden die oben genannten personenbezogenen Daten von folgenden Auftragsverarbeitern zu Wartungs- und
            Supportzwecken verarbeitet:
            <div class="my-3">
              <div>Arivo GmbH</div>
              <div>Am Innovationspark 10</div>
              <div>8020 Graz</div>
              <a
                [href]="'mailto:info@arivo.co'"
                class="hover:text-interaction-light text-interaction no-underline"
                [innerText]="'info@arivo.co'"
              >
              </a>
            </div>
            Die Arivo GmbH bedient sich an unterschiedlichen Sub-Auftragsverarbeitern. Nähere Informationen hierzu finden Sie unter
            <a
              [href]="'https://arivo.co/parksystem-datenschutz'"
              class="hover:text-interaction-light text-interaction no-underline"
              [innerText]="' https://arivo.co/parksystem-datenschutz'"
              target="_blank"
            >
            </a>
          </div>
        </li>
        <li class="font-bold text-lg mb-3">
          Erhobene Daten und Verwendungszweck
          <div class="body-medium">
            Wir bewahren Ihre personenbezogenen Daten zu Ihrem Arivo Customer Portal Konto auf, solange Sie das Portal nutzen. Wenn Sie ihr
            Konto löschen wollen, kontaktieren Sie hierzu den Support. Ihr Konto wird zunächst ausgeblendet und nach Ablauf einer Frist
            unwiderruflich gelöscht, es sei denn, eine längere Aufbewahrung ist zur Erfüllung gesetzlicher Aufbewahrungspflichten
            erforderlich. Überdies speichern wir Ihre personenbezogenen Daten im Einzelfall zur Abwehr oder Durchsetzung von
            Rechtsansprüchen.
          </div>
        </li>
        <li class="font-bold text-lg mb-3">
          Betroffenenrechte
          <div class="body-medium">
            Jeder Betroffene hat uns gegenüber im Zusammenhang mit der Verarbeitung der personenbezogenen Daten folgende Rechte:
            <ul class="my-3">
              <li>Recht auf Auskunft der gespeicherten Daten (Art. 15 DSGVO)</li>
              <li>Recht auf Berichtigung von unvollständigen oder falschen Daten (Art 16 DSGVO)</li>
              <li>Recht auf Löschung der Daten (Art 17 DSGVO)</li>
              <li>Recht auf Einschränkung der Verarbeitung (Art 18 DSGVO)</li>
              <li>Recht auf Datenübertragbarkeit (Art. 20 DSGVO)</li>
              <li>Widerspruchsrecht (Art. 21 DSGVO)</li>
              <li>
                Recht auf Beschwerde bei einer Aufsichtsbehörde (<a
                  [href]="'https://dsb.gv.at'"
                  class="hover:text-interaction-light text-interaction no-underline"
                  [innerText]="'https://dsb.gv.at'"
                  target="_blank"
                >
                </a
                >)
              </li>
            </ul>
          </div>
        </li>
      </ol>
    </div>
  </div>
  <div class="card-body">
    <div class="headline-small mb-5" id="garage-dsgvo-header">Datenschutzerklärung für Parkgaragen, Parkhäuser und Parkplätze</div>
    <div class="body-medium">
      <ol>
        <li class="mb-3 font-bold text-lg">
          <p>Zwecke der Datenverarbeitung</p>
          <div class="body-medium">
            Wir werden Ihre personenbezogenen Daten zu folgenden Zwecken verarbeiten:
            <ul style="list-style-type: lower-latin" class="my-3">
              <li>Dauerparkverwaltung</li>
              <li>Tarifberechnung für Parken nach Zeit</li>
              <li>Kontrolle der Nutzungsbestimmungen (z.B. Parken über zwei Stellplätze)</li>
              <li>Kontrolle der rechtskonformen Verwendung (z.B. Überschreitung der Gratisparkzeit)</li>
              <li>Schutz der Einrichtungen (z.B. Vandalismus)</li>
              <li>Verfolgung missbräuchlicher Verwendung (z.B. Ausfahrt ohne Bezahlung)</li>
              <li>Online Customer Portal</li>
              <li>Automatisierte Zu- und Ausfahrt mittels KFZ-Kennzeichen</li>
            </ul>
            Diese Daten erheben wir mittels:
            <ul class="my-3">
              <li>der von Ihnen zur Verfügung gestellten Daten zur Vertragserstellung</li>
              <li>Bildaufzeichnung, Auswertung der Kennzeichendaten</li>
              <li>Daten zur Vertragserfüllung</li>
              <li>Zugangs- und Nutzungsdaten des Online Customer Portals</li>
            </ul>
            Die Bereitstellung Ihrer personenbezogenen Daten ist freiwillig. Auf die Bildaufzeichnung und die Erfassung von Kennzeichendaten
            wird entsprechend der gesetzlichen Vorgaben hingewiesen.
          </div>
        </li>
        <li class="mb-3 font-bold text-lg">
          <p>Rechtsgrundlagen der Verarbeitung</p>
          <div class="body-medium">
            <ul class="my-3">
              <li>
                Für die Abrechnung der Parkgebühren (sowohl beim Kurzparken als auch bei Dauerparkern) ist die Rechtsgrundlage
                „Vertragserfüllung“ (Art. 6 Abs. 1 lit. b DSGVO)
              </li>
              <li>Für die Kennzeichenerfassung bei Dauerparkern ist die Rechtsgrundlage Ihre Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)</li>
              <li>
                Für die Kennzeichenerfassung bei Kurzparkern ist die Rechtsgrundlage das berechtigte Interesse des Verantwortlichen (Art. 6
                Abs. 1 lit. f DSGVO), welche darin bestehen, die oben unter Punkt 1 (a bis h) genannten Zwecke zu erreichen. Gegen diese
                Datenverarbeitung steht Ihnen ein Widerspruchsrecht nach Art. 21 Abs. 1 DSGVO zu, wenn bei Ihnen Gründe vorliegen, die sich
                aus Ihrer besonderen Situation ergeben.
              </li>
              <li>
                Für die Bildverarbeitung insbesondere des Einganges und des Zutrittsbereiches, der Ein- und Ausfahrtstore, der Kassen und
                Automaten, der Stiegenhäuser sowie der Parkdecks gemäß § 12 Abs. 3 Z 2 DSG. Die Bildaufzeichnungen werden nur im Anlassfall
                ausgewertet, wenn entweder das überwachte Objekt selbst (Garage) oder darin abgestellte Fahrzeuge Gegenstand einer
                Rechtsverletzung wurden.
              </li>
            </ul>
          </div>
        </li>

        <li class="mb-3 font-bold text-lg">
          <p>Übermittlung Ihrer personenbezogenen Daten</p>
          <div class="body-medium">
            Zu den oben genannten Zwecken werden Ihre personenbezogenen Daten an folgende Empfänger - im Anlassfall - übermittelt:
            <ul class="my-3">
              <li>Auftragsverarbeiter (z.B. Callcenter, Beschwerdemanagement)</li>
              <li>Abrechnungsplattformen (Tankkarten, Mobilitätskarten etc.)</li>
              <li>Versicherungen (z.B. bei Beschädigungen)</li>
              <li>Inkassobüro, Rechtsanwalt, Gerichte</li>
              <li>Behörden im Rahmen ihres Aufgabenbereiches</li>
              <li>Auftraggeber des Parkflächenbetreibers als Betriebsführer</li>
            </ul>
          </div>
        </li>

        <li class="mb-3 font-bold text-lg">
          <p>Speicherdauer</p>
          <div class="body-medium">
            Ihre personenbezogenen Daten werden von uns nur so lange aufbewahrt, wie dies zur Vertragserfüllung erforderlich ist, um die
            unter Punkt 1 genannten Zwecke zu erreichen.
            <ul class="my-3 font-bold" style="list-style-type: lower-latin">
              <li>
                Bei nicht registrierten Kunden:
                <ul class="my-3 body-medium">
                  <li>
                    Die Anonymisierung des Bildes des Kennzeichens erfolgt bei normaler Parktransaktion in der Regel 24 Stunden nach der
                    letzten aufgezeichneten Aktion <sup class="cursor-pointer" (click)="scrollToHeader('footnote-1')">1</sup>.
                  </li>
                  <li>
                    Bei abgeschlossenen Parkvorgängen mit offenen Kosten erfolgt die Anonymisierung 91 Tage nach Ablauf der Nachzahlfrist.
                    Diese Daten werden für die Abmahnung und bei Anfechtungen als Beweismittel benötigt.
                  </li>
                  <li>
                    Nicht registrierte Kunden, welche innerhalb der Gratiszeit ausfahren oder bei der Einfahrt umdrehen werden nach 5
                    Minuten anonymisiert.
                  </li>
                  <li>Die übrigen Daten werden nach Ablauf der steuerrechtlichen Aufbewahrungsfristen von sieben Jahren gelöscht.</li>
                </ul>
              </li>
              <li>
                Bei registrierten Kunden:
                <ul class="my-3 body-medium">
                  <li>Parkvorgänge von registrierten Kunden, welche keine Kosten erzeugt haben: 30 Tage nach der Ausfahrt.</li>
                  <li>Parkvorgänge, mit Zahlung auf Rechnung: 30 Tage nach Rechnungslegung (Monatsrechnung).</li>
                  <li>
                    Abweisungen<sup class="cursor-pointer ml-0.5" (click)="scrollToHeader('footnote-2')">2</sup> von registrierten Kunden: 7
                    Tage nach Abweisung
                  </li>
                  <li>Die übrigen Daten werden nach Ablauf der steuerrechtlichen Aufbewahrungsfristen von sieben Jahren gelöscht.</li>
                </ul>
              </li>
              <li>
                Fehlerhafte Bewegungen:
                <ul class="my-3 body-medium">
                  <li>
                    Parkvorgänge, bei denen zwei Einfahrten erfasst wurde, jedoch keine zugehörige Ausfahrt: Anonymisierung 30 Tage nach der
                    Einfahrt
                  </li>
                  <li>
                    Parkvorgänge, bei denen eine Ausfahrt erfasst wurde, jedoch keine zugehörige Einfahrt: Anonymisierung 30 Tage nach der
                    Ausfahrt
                  </li>
                  <li>Offene Parkvorgänge, welche noch nicht abgeschlossen sind: Anonymisierung nach 90 Tagen</li>
                </ul>
              </li>
              <li>
                Bildverarbeitung („Videoüberwachung“):
                <div class="my-3 body-medium">
                  Aufgezeichnete Daten sind, sofern sie nicht aus konkretem Anlass für die Verwirklichung der zu Grunde liegenden Schutz-
                  oder Beweissicherungszwecke oder für Zwecke nach § 12 Abs 3 Z 2 DSG benötigt werden, spätestens nach 14 Tagen zu löschen
                  bzw. anonymisieren. Die Abweichung zur gesetzlichen Löschfrist des § 13 Abs 3 DSG wird zur Feststellung von
                  Zahlungsausfällen von Kreditkartentransaktionen benötigt, da der Parkflächenbetreiber erst nach bis zu zwei Wochen
                  erfährt, dass eine Kreditkartentransaktion von der Bank nicht durchgeführt worden ist. Ohne eine Speicherung für 14 Tage
                  wäre eine Geltendmachung von Ansprüchen nicht mehr möglich. Die Verwendung der Aufzeichnungen für andere Zwecke ist nicht
                  zulässig.
                </div>
              </li>
            </ul>
          </div>
        </li>

        <li class="mb-3 font-bold text-lg">
          <p>Ihre Rechte im Zusammenhang mit personenbezogenen Daten</p>
          <div class="body-medium">
            Jeder Betroffene hat uns gegenüber im Zusammenhang mit der Verarbeitung der personenbezogenen Daten folgende Rechte:
            <ul class="my-3">
              <li>Recht auf Auskunft der gespeicherten Daten (Art. 15 DSGVO)</li>
              <li>Recht auf Berichtigung von unvollständigen oder falschen Daten (Art 16 DSGVO)</li>
              <li>Recht auf Löschung der Daten (Art 17 DSGVO)</li>
              <li>Recht auf Einschränkung der Verarbeitung (Art 18 DSGVO)</li>
              <li>Recht auf Datenübertragbarkeit (Art. 20 DSGVO)</li>
              <li>Widerspruchsrecht (Art. 21 DSGVO)</li>
              <li>
                Recht auf Beschwerde bei einer Aufsichtsbehörde (<a
                  [href]="'https://dsb.gv.at'"
                  class="hover:text-interaction-light text-interaction no-underline"
                  [innerText]="'https://dsb.gv.at'"
                  target="_blank"
                >
                </a
                >)
              </li>
            </ul>
          </div>
        </li>

        <li class="mb-3 font-bold text-lg">
          <p>Unsere Kontaktdaten</p>
          <div class="body-medium">
            Verantwortlicher:
            <ul class="my-3">
              <li>{{ providerData.name }}</li>
              <li *ngIf="providerData.representative">{{ providerData.representative }}</li>
              <li>{{ providerData.address }}</li>
              <li>{{ providerData.zip_code }}, {{ providerData.city }}</li>
              <li>{{ providerData.country }}</li>
              <li *ngIf="providerData.contact_phone_number">
                <a
                  [href]="'mailto:' + providerData.contact_email"
                  class="hover:text-interaction-light text-interaction no-underline"
                  [innerText]="providerData.contact_email"
                ></a>
              </li>
            </ul>
          </div>
        </li>
      </ol>
      <hr />
      <div class="mt-3">
        Wir behalten uns das Recht vor, diese Datenschutzerklärungen aufgrund rechtlicher oder technischer Entwicklung jederzeit anzupassen.
      </div>
      <div class="mt-5 body-small">
        <div id="footnote-1" class="mb-2">
          <sup class="mr-1">1</sup> Nach der Ausfahrt, wenn zuvor bezahlt wurde oder nach der Bezahlung, wenn diese nach der Ausfahrt
          erfolgte.
        </div>
        <div id="footnote-2">
          <sup class="mr-1">2</sup> Abweisungen sind Fälle, wo ein Fahrzeug nicht Einfahren darf, weil es eine Regel verletzt (z.B. Anzahl
          der Fahrzeuge laut Vertragslimit überschritten).
        </div>
      </div>
    </div>
  </div>
</div>
<div id="dev-bottom"></div>
