import { Component, OnInit } from '@angular/core';
import { ProviderGeneralData } from '../../../shared/models/providerSettings';
import { ProviderSettingsService } from '../../../core/services/provider-settings.service';

@Component({
  selector: 'app-data-privacy',
  templateUrl: './data-privacy.component.html',
  styleUrl: './data-privacy.component.scss',
})
export class DataPrivacyComponent implements OnInit {
  providerData!: ProviderGeneralData;

  constructor(private providerSettingsService: ProviderSettingsService) {}

  ngOnInit() {
    this.providerData = this.providerSettingsService.getProviderGeneralData();
  }

  scrollToHeader(id: 'portal-dsgvo-header' | 'garage-dsgvo-header' | 'footnote-1' | 'footnote-2') {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
