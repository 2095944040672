import { Component } from '@angular/core';

@Component({
  selector: 'app-accessibility',
  templateUrl: './accessibility.component.html',
  styleUrl: './accessibility.component.scss',
})
export class AccessibilityComponent {
  get baseUrl(): string {
    return window.location.origin;
  }
}
