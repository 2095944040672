export const environment = {
  name: 'staging',
  website_title: 'Staging Serviceportal',
  backend_url: '',
  dev_adyen_backend_url: 'http://localhost:3000',
  adyen_iframe_url: 'https://adyen.portal.arivo.fun',
  production: true,
  featureFlags: {
    enable_marketing_consent: false,
    enable_contract_creation_dashboard: true,
    enable_contract_creation_products: true,
    enable_contract_termination: true,
    enable_contract_qr_code: true,
    enable_iframe_adyen: true,
    door_opener_qr_code: false,
    enable_app_and_notifications: false,
    enable_cookie_consent: false,
    dev_enable_demo_login: false,
    dev_enable_demo_login_only: false,
    dev_enable_mock_calls: false,
    translation_key: 'default',
  },
};
