import { Component, DestroyRef, inject, Inject, OnInit, ViewChild } from '@angular/core';
import { Garage } from '../../shared/models/garage';
import { ActivatedRoute, Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { TypedRoute } from '../../shared/utils/router.utils';
import { ProductsResolve } from '../../shared/models/routeTyping';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ArivoBottomSheetDialogComponent } from '../../shared/components/ui-components/arivo-bottom-sheet-dialog/arivo-bottom-sheet-dialog.component';
import { UrlPipe } from '../../core/pipes/url.pipe';
import L from 'leaflet';

interface LatLng {
  lat: number;
  lng: number;
}

interface MapMarker extends Garage {
  marker: L.Marker;
}

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrl: './products.component.scss',
})
export class ProductsComponent implements OnInit {
  private destroyRef: DestroyRef = inject(DestroyRef);

  map?: L.Map;
  display: 'map' | 'list' = 'map';

  mapMarkers: MapMarker[] = [];
  filteredMapMarkers: MapMarker[] = [];
  markerFilter: string = '';

  @ViewChild('garageDetailsDialog') garageDetailsDialog?: ArivoBottomSheetDialogComponent;
  selectedMapMarker?: MapMarker;

  userLocation?: LatLng;
  centerMapToUserLocation: boolean = false;
  displayUserLocationWarning: boolean = false;

  constructor(
    private _router: Router,
    private _urlPipe: UrlPipe,
    private _decimalPipe: DecimalPipe,
    @Inject(ActivatedRoute) private route: TypedRoute<ProductsResolve>,
  ) {}

  ngOnInit(): void {
    this.initializeMap();

    this.route.queryParams.subscribe((params) => {
      if (!params['garageId']) {
        this.updateSelectedMarker();
      }
    });

    if (this.selectedMapMarker) {
      this.map?.panTo([this.selectedMapMarker.location.latitude, this.selectedMapMarker.location.longitude]);
    }

    navigator.permissions
      .query({ name: 'geolocation' })
      .then((permissionStatus) => {
        let previousState = undefined;
        let currentState = permissionStatus.state;
        permissionStatus.onchange = () => {
          previousState = currentState;
          currentState = permissionStatus.state;

          if (previousState !== 'prompt') {
            window.location.reload();
          }
        };

        if (permissionStatus.state === 'prompt') {
          this.centerMapToUserLocation = true;
        }
      })
      .catch((e) => {});

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          this.userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          if (this.map) {
            // Display user's location on the map
            const marker = L.marker([position.coords.latitude, position.coords.longitude], {
              icon: L.icon({
                iconUrl: this._urlPipe.transform('/assets/mapmarkers/location.svg'),
                iconSize: [42, 42],
                iconAnchor: [21, 21],
                popupAnchor: [0, -42],
              }),
              zIndexOffset: 1000000,
            });
            marker.addTo(this.map);

            // Add a button to pan to user's location
            const goToUserLocationButton = L.Control.extend({
              options: {
                position: 'bottomright',
              },
              onAdd: function (map: L.Map) {
                const container = L.DomUtil.create('button', 'leaflet-bar leaflet-control leaflet-control-custom map-button');
                container.innerHTML = '<i class="fa-light fa-location-crosshairs"></i>';

                container.onclick = function () {
                  map.panTo([position.coords.latitude, position.coords.longitude]);
                };

                return container;
              },
            });
            this.map.addControl(new goToUserLocationButton());

            if (this.centerMapToUserLocation) {
              this.map.panTo([position.coords.latitude, position.coords.longitude]);
            }
          }

          this.mapMarkers.sort((a: MapMarker, b: MapMarker) => {
            if (this.userLocation) {
              return (
                this.computeDistanceBetween(this.userLocation, {
                  lat: a.location.latitude,
                  lng: a.location.longitude,
                }) -
                this.computeDistanceBetween(this.userLocation, {
                  lat: b.location.latitude,
                  lng: b.location.longitude,
                })
              );
            } else {
              return 0;
            }
          });
        },
        (error: GeolocationPositionError) => {
          this.displayUserLocationWarning = true;
        },
        {
          enableHighAccuracy: true,
        },
      );
    }
  }

  initializeMap(): void {
    this.map = L.map('map', {
      maxBounds: [
        [-90, -180],
        [90, 180],
      ],
      minZoom: 3,
      zoomControl: false,
    }).setView([48.21155881631771, 16.365026225916996], 8);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a rel="noopener noreferrer" target="_blank" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      detectRetina: true,
    }).addTo(this.map);

    // HACK: The map may sometimes not render completely. This workaround forces the map to render correctly.
    setTimeout(function () {
      window.dispatchEvent(new Event('resize'));
    });

    this.route.data.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data) => {
      let center = {
        lat: 0,
        lng: 0,
      };

      this.mapMarkers = data.productsApiResponse.garages.map((garage: Garage) => {
        center.lat += garage.location.latitude / data.productsApiResponse.garages.length;
        center.lng += garage.location.longitude / data.productsApiResponse.garages.length;

        const marker = L.marker([garage.location.latitude, garage.location.longitude], {
          icon: L.icon({
            iconUrl: this._urlPipe.transform('/assets/mapmarkers/marker-blue.svg'),
            iconSize: [28, 42],
            iconAnchor: [14, 42],
            popupAnchor: [0, -42],
          }),
        });

        const mapMarker = {
          ...garage,
          marker: marker,
        };

        marker.on('click', () => {
          this.updateSelectedMarker(mapMarker);
        });

        if (this.map) {
          marker.addTo(this.map);
        }

        const garageId = this.route.snapshot.queryParams['garageId'];
        if (garageId === mapMarker.id) {
          this.updateSelectedMarker(mapMarker);
        }

        return mapMarker;
      });

      this.filterMarkers();

      if (this.mapMarkers.length === 0) {
        this.changeDisplay('list');

        center = {
          lat: 47.0420163,
          lng: 15.4389372,
        };
      }

      let zoom = 14;
      if (data.productsApiResponse.map_options?.zoom) {
        zoom = data.productsApiResponse.map_options.zoom;
      }

      // Use map center from backend if available, otherwise use the average location of all garages
      if (data.productsApiResponse.map_options?.center) {
        this.map?.setView(
          [data.productsApiResponse.map_options.center.latitude, data.productsApiResponse.map_options.center.longitude],
          zoom,
        );
      } else {
        this.map?.setView([center.lat, center.lng], zoom);
      }
    });
  }

  updateSelectedMarker(marker?: MapMarker, panToMarker: boolean = false): void {
    if (marker === this.selectedMapMarker) {
      this.resetSelectedMarker();
      return;
    }
    if (this.selectedMapMarker) {
      this.selectedMapMarker.marker.setIcon(
        L.icon({
          iconUrl: this._urlPipe.transform('/assets/mapmarkers/marker-blue.svg'),
          iconSize: [28, 42],
          iconAnchor: [14, 42],
          popupAnchor: [0, -42],
        }),
      );
    }

    if (marker) {
      marker.marker.setIcon(
        L.icon({
          iconUrl: this._urlPipe.transform('/assets/mapmarkers/marker-blue.svg'),
          shadowUrl: this._urlPipe.transform('/assets/mapmarkers/marker-blue-selected-shadow.svg'),
          iconSize: [28, 42],
          shadowSize: [72, 72],
          iconAnchor: [14, 42],
          shadowAnchor: [36, 58],
          popupAnchor: [0, -42],
        }),
      );

      if (panToMarker) {
        this.map?.panTo([marker.location.latitude, marker.location.longitude]);
      }

      if (this.garageDetailsDialog) {
        this.garageDetailsDialog.open = true;
      }

      this._router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          garageId: marker.id,
        },
        queryParamsHandling: 'merge',
      });

      this.selectedMapMarker = marker;
    } else {
      this.resetSelectedMarker();
    }
  }

  changeDisplay(display: 'map' | 'list'): void {
    this.display = display;

    // HACK: The map may sometimes not render completely. This workaround forces the map to render correctly.
    if (this.display === 'map') {
      setTimeout(function () {
        window.dispatchEvent(new Event('resize'));
      });
    }
  }

  distanceToUser(mapMarker: MapMarker): number | undefined {
    if (this.userLocation) {
      return this.computeDistanceBetween(this.userLocation, {
        lat: mapMarker.location.latitude,
        lng: mapMarker.location.longitude,
      });
    } else {
      return undefined;
    }
  }

  formatAddress(garage: Garage): string {
    let formattedStreet = garage.street + (garage.number ? ' ' + garage.number : '');
    return `${formattedStreet}, ${garage.zip_code} ${garage.city}`;
  }

  formatDistance(distance?: number): string | undefined {
    if (distance) {
      if (distance >= 1000) {
        return `${this._decimalPipe.transform(Math.round(distance / 100) / 10)} km`;
      } else {
        return `${this._decimalPipe.transform(Math.round(distance))} m`;
      }
    } else {
      return undefined;
    }
  }

  computeDistanceBetween(a: LatLng, b: LatLng): number {
    const r = 6378137;

    const dLat = ((b.lat - a.lat) * Math.PI) / 180;
    const dLng = ((b.lng - a.lng) * Math.PI) / 180;
    const lat1 = (a.lat * Math.PI) / 180;
    const lat2 = (b.lat * Math.PI) / 180;

    const x = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLng / 2) * Math.sin(dLng / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(x), Math.sqrt(1 - x));

    return r * c;
  }

  resetSelectedMarker() {
    this._router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        garageId: null,
      },
      queryParamsHandling: 'merge',
    });

    this.selectedMapMarker = undefined;
  }

  filterMarkers(): void {
    if (!this.mapMarkers || this.mapMarkers.length === 0 || this.markerFilter.trim() === '') {
      this.filteredMapMarkers = this.mapMarkers;
      return;
    }
    this.filteredMapMarkers = this.mapMarkers.filter((marker) => {
      return (
        marker.name.toLowerCase().includes(this.markerFilter.toLowerCase()) ||
        (marker.street.toLowerCase() + (marker.number ? ' ' + marker.number.toLowerCase() : '')).includes(this.markerFilter.toLowerCase())
      );
    });
  }

  clearMarkerFilter() {
    this.markerFilter = '';
    this.filterMarkers();
  }
}
