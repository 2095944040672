import { Component, DestroyRef, inject, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TypedRoute } from '../../../shared/utils/router.utils';
import { FormBuilder } from '@angular/forms';
import { ContractLicensePlate, LicensePlate } from '../../../shared/models/licensePlate';
import { GarageContract, GarageContractTerminate, GarageContractUpdate } from '../../../shared/models/contract';
import { ArivoBottomSheetDialogComponent } from '../../../shared/components/ui-components/arivo-bottom-sheet-dialog/arivo-bottom-sheet-dialog.component';
import { ContractsApiService } from '../contracts-api.service';
import { ContractEditResolve } from '../../../shared/models/routeTyping';
import { ArivoDialogComponent } from '../../../shared/components/ui-components/arivo-dialog/arivo-dialog.component';
import { ToastService } from '../../../shared/services/toast.service';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { RouterService } from '../../../shared/services/router.service';
import { formatAddress, formatDateWithTimezone } from '../../../shared/utils/common.utils';
import { MatCalendar } from '@angular/material/datepicker';
import { FeatureFlagService } from '../../../core/services/feature-flag.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-contract-edit',
  templateUrl: './contract-edit.component.html',
  styleUrl: './contract-edit.component.scss',
})
export class ContractEditComponent {
  @ViewChild('qrDialog') qrDialog!: ArivoDialogComponent;
  @ViewChild('terminateContractDialog') terminateContractDialog!: ArivoBottomSheetDialogComponent;
  @ViewChild('endDatepicker') endDatepicker!: MatCalendar<Date>;
  destroyRef = inject(DestroyRef);

  contract!: GarageContract;
  userLicensePlates: ContractLicensePlate[] = [];
  enableContractTermination: boolean = this.featureFlagService.getFeatureFlag('enable_contract_termination');
  earliestTerminationDate: Date = new Date();
  selectedTerminationDate: Date = new Date();

  constructor(
    private router: Router,
    private _datePipe: DatePipe,
    private _formBuilder: FormBuilder,
    private toastService: ToastService,
    private routerService: RouterService,
    private translateService: TranslateService,
    private contractsApiService: ContractsApiService,
    private featureFlagService: FeatureFlagService,
    @Inject(ActivatedRoute) private route: TypedRoute<ContractEditResolve>,
  ) {
    this.route.data.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data) => {
      this.contract = data.contractEditApiResponse.garage_contract;
      this.userLicensePlates = data.contractEditApiResponse.license_plates.map((licensePlate: LicensePlate) => {
        if (
          this.contract.license_plates.some(
            (contractLicensePlate) =>
              contractLicensePlate.license_plate === licensePlate.license_plate &&
              contractLicensePlate.license_plate_country === licensePlate.license_plate_country,
          )
        ) {
          return {
            ...licensePlate,
            enabled: true,
          };
        } else {
          return {
            ...licensePlate,
            enabled: false,
          };
        }
      });
      if (this.enableContractTermination) {
        this.setTerminationDate();
      }
    });
  }

  contractForm = this._formBuilder.nonNullable.group({
    licensePlates: [<Array<LicensePlate>>[]],
  });

  get isRpcFallbackGarage(): boolean {
    if (!this.contract.garage) {
      return false;
    }
    return (
      !!this.contract.garage.name &&
      !this.contract.garage.city &&
      !this.contract.garage.street &&
      !this.contract.garage.number &&
      !this.contract.garage.zip_code
    );
  }

  updateLicensePlates(licensePlates: LicensePlate[]): void {
    this.contractForm.controls.licensePlates.patchValue(licensePlates);
    this.contractForm.controls.licensePlates.updateValueAndValidity();
  }

  back(): void {
    this.router.navigateByUrl(this.routerService.previousUrl ?? '/dashboard');
  }

  finish(): void {
    this.contractForm.markAllAsTouched();

    if (this.contractForm.valid) {
      this.contract.license_plates = this.contractForm.controls.licensePlates.value;

      let updatePayload: GarageContractUpdate = {
        license_plates: this.contract.license_plates,
      };

      this.contractsApiService
        .updateContract(updatePayload, this.contract.id)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: () => {
            this.router.navigateByUrl('/dashboard').finally(() => {
              this.toastService.add({
                message: this.translateService.instant('Contracts.UpdateContractSuccess', {
                  name: this.contract.name,
                }),
                type: 'success',
              });
            });
          },
          error: (error) => {
            if (error.status === 400 && error.error.detail) {
              const detail = error.error.detail;
              for (const key in detail) {
                if (detail.hasOwnProperty(key)) {
                  detail[key].forEach((message: string) => {
                    this.toastService.add({
                      message: message,
                      type: 'error',
                    });
                  });
                }
              }
            } else {
              this.toastService.add({
                message: this.translateService.instant('Contracts.UpdateContractError'),
                type: 'error',
              });
            }
          },
        });
    }
  }

  endDatepickerFilter = (d: Date | null): boolean => {
    const date = d || new Date();

    // Check if the date is the last day of the month
    const isLastDayOfMonth = date.getDate() === new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

    // Filter out dates before the earliest termination date and ensure it's the last day of the month
    return date >= this.earliestTerminationDate && isLastDayOfMonth;
  };

  setTerminationDate(): void {
    if (this.contract.start) {
      let earliestDate = new Date(this.contract.start);
      const currentDate = new Date();

      if (earliestDate < currentDate) {
        earliestDate = currentDate;
      }

      if (this.contract.minimum_duration_months) {
        let minimumContractDurationEndDate = new Date(this.contract.start);
        minimumContractDurationEndDate.setMonth(minimumContractDurationEndDate.getMonth() + this.contract.minimum_duration_months);

        if (earliestDate < minimumContractDurationEndDate) {
          earliestDate = minimumContractDurationEndDate;
        }
      }

      if (this.contract.notice_period_months) {
        let noticePeriodEndDate = new Date();
        //This is so that if it's the 31st, it also allows the 30th of the next month.
        noticePeriodEndDate.setDate(1);
        noticePeriodEndDate.setMonth(noticePeriodEndDate.getMonth() + this.contract.notice_period_months);

        if (earliestDate < noticePeriodEndDate) {
          earliestDate = noticePeriodEndDate;
        }

        // Set to last day of the month
        earliestDate = new Date(earliestDate.getFullYear(), earliestDate.getMonth() + 1, 0);
      }

      this.earliestTerminationDate = earliestDate;
      this.selectedTerminationDate = earliestDate;
    }
  }

  resetTerminationDate(): void {
    this.selectedTerminationDate = this.earliestTerminationDate;
    this.endDatepicker.activeDate = this.earliestTerminationDate;
    this.endDatepicker.updateTodaysDate();
  }

  terminateContract(): void {
    if (!this.enableContractTermination) {
      this.toastService.add({
        message: this.translateService.instant('Contracts.TerminateContractDisabled'),
        type: 'error',
      });
      return;
    }
    if (this.selectedTerminationDate) {
      let terminatePayload: GarageContractTerminate = {
        end: formatDateWithTimezone(this.selectedTerminationDate),
      };

      this.contractsApiService
        .terminateContract(terminatePayload, this.contract.id)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: () => {
            this.router.navigateByUrl('/dashboard').finally(() => {
              this.toastService.add({
                message: this.translateService.instant('Contracts.TerminateContractSuccess', {
                  name: this.contract.name,
                  endDate: this._datePipe.transform(this.selectedTerminationDate),
                }),
                type: 'success',
              });
            });
          },
          error: (error) => {
            if (error.status === 400 && error.error.detail) {
              const detail = error.error.detail;
              for (const key in detail) {
                if (detail.hasOwnProperty(key)) {
                  detail[key].forEach((message: string) => {
                    this.toastService.add({
                      message: message,
                      type: 'error',
                    });
                  });
                }
              }
            } else {
              this.toastService.add({
                message: this.translateService.instant('Contracts.UpdateContractError'),
                type: 'error',
              });
            }
          },
        });

      this.terminateContractDialog.open = false;
    }
  }

  protected readonly formatAddress = formatAddress;
}
