import { Component, DestroyRef, Inject, inject, OnInit, ViewChild } from '@angular/core';
import { BillingInvoice, BillingInvoiceStatus } from '../../../shared/models/invoice';
import { ActivatedRoute } from '@angular/router';
import { TypedRoute } from '../../../shared/utils/router.utils';
import { BillingResolve } from '../../../shared/models/routeTyping';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { breakPoints } from '../../../core/utils/common.data';
import { Money } from '../../../shared/models/common';
import { sortArray } from '../../../shared/utils/common.utils';
import { ArivoPaginatorComponent } from '../../../shared/components/arivo-paginator/arivo-paginator.component';

interface InvoicePeriod {
  status: BillingInvoiceStatus | null;
  costs: Money;
  invoices: BillingInvoice[];
  expanded: boolean;
}

@Component({
  selector: 'app-billing-invoices',
  templateUrl: './billing-invoices.component.html',
  styleUrl: './billing-invoices.component.scss',
})
export class BillingInvoicesComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  startOpen: boolean = false;

  invoicePeriods: string[] = [];
  invoices: { [key: string]: InvoicePeriod } = {};
  clearedStatuses = ['cleared', 'refunded', 'deducted'];

  orderedBy: 'date' = 'date';
  orderedByOrder: 'asc' | 'desc' = 'desc';
  clearedStatus = ['cleared', 'refunded', 'deducted'];
  @ViewChild('paginator') paginator: ArivoPaginatorComponent | undefined;

  constructor(@Inject(ActivatedRoute) private route: TypedRoute<BillingResolve>) {}

  ngOnInit() {
    this.startOpen = window.innerWidth > breakPoints.md;
    this.route.data.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data) => {
      const invoices = sortArray(data.billingApiResponse.invoices, (x) => x.invoice_date, 'desc');

      let startDate: Date | undefined;
      let endDate: Date | undefined;

      // Group invoices by period
      invoices.forEach((invoice) => {
        const invoiceDateFirstOfMonth = new Date(invoice.invoice_date.getFullYear(), invoice.invoice_date.getMonth(), 1);
        const key = this.getFormattedPeriod(invoiceDateFirstOfMonth);

        if (!startDate || invoiceDateFirstOfMonth < startDate) {
          startDate = invoiceDateFirstOfMonth;
        }
        if (!endDate || invoiceDateFirstOfMonth > endDate) {
          endDate = invoiceDateFirstOfMonth;
        }

        if (key in this.invoices) {
          if (this.invoices[key].status !== 'open' && !this.clearedStatus.includes(invoice.status)) {
            this.invoices[key].status = 'open';
          }
          if (invoice.status !== 'nonrefundable') {
            this.invoices[key].costs.amount += invoice.costs.amount;
          }
          this.invoices[key].invoices.push(invoice);
        } else {
          this.invoices[key] = {
            status: this.clearedStatus.includes(invoice.status) ? 'cleared' : 'open',
            costs: {
              amount: invoice.status !== 'nonrefundable' ? invoice.costs.amount : 0,
              currency: invoice.costs.currency,
            },
            invoices: [invoice],
            expanded: false,
          };
        }
      });

      // Create a list of months between the earliest and the latest invoice period
      if (startDate && endDate) {
        for (let date = new Date(endDate.getTime()); date >= startDate; date.setMonth(date.getMonth() - 1)) {
          const key = this.getFormattedPeriod(date);
          if (!(key in this.invoices)) {
            this.invoices[key] = {
              status: null,
              costs: {
                amount: 0,
                currency: invoices[0].costs.currency,
              },
              invoices: [],
              expanded: false,
            };
          }
          this.invoicePeriods.push(key);
        }
      }
    });
  }

  get paginatedPeriods() {
    if (this.paginator) {
      return this.invoicePeriods.slice(this.paginator.startIndex, this.paginator.endIndex);
    } else {
      return this.invoicePeriods.slice(0, 10);
    }
  }

  orderByDate() {
    this.orderedByOrder = this.orderedBy === 'date' && this.orderedByOrder === 'asc' ? 'desc' : 'asc';
    this.orderedBy = 'date';

    sortArray(this.invoicePeriods, (x) => x, this.orderedByOrder);
  }

  collapse(start: number, end: number) {
    for (let i = start; i < end; i++) {
      this.invoices[this.invoicePeriods[i]].expanded = false;
    }
  }

  getFormattedPeriod(date: Date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const formattedMonth = month >= 10 ? month : `0${month}`;
    return `${year}-${formattedMonth}`;
  }
}
