import { DestroyRef, inject, Injectable } from '@angular/core';
import { BehaviorSubject, pairwise } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CoreDataService } from '../../core/services/core-data.service';
import { createDialogConfig } from '../components/arivo-dialog-container/dialog-config';
import { FeatureFlagService } from '../../core/services/feature-flag.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export type Dialog = {
  /** The title of the dialog. */
  title: string;
  /** The subtitle of the dialog (optional). */
  subtitle?: string;
  /** The content of the dialog. This is the main informational text. */
  content: string;
  /** Indicates if the dialog is dismissible (optional). Dismissible means it has an 'Understood' button, can also be
   * set manually via the dismissButtonText property. Usually this is displayed alongside a regular button. */
  dismissible?: boolean;
  /** The text for the dismiss button (optional). */
  dismissButtonText?: string;
  /** The text for the main dialog button (optional). Usually this button is what you want the user to press. */
  buttonText?: string;
  /** The route for the dialog button (optional) if it should redirect somewhere. */
  buttonRoute?: {
    /** The link for the button route. */
    link: string;
    /** The query parameters for the button route (optional). */
    queryParams?: { [key: string]: string | null };
  };
  /** The type of the dialog. Changes icon and color scheme. */
  type: 'success' | 'info' | 'warning' | 'error';
};

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  destroyRef = inject(DestroyRef);
  dialogues: BehaviorSubject<Dialog[]> = new BehaviorSubject<Dialog[]>([]);
  private dialogConfig = createDialogConfig(this.translateService, this.featureFlagService);

  constructor(
    private translateService: TranslateService,
    private coreDataService: CoreDataService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.coreDataService.warningData
      .pipe(takeUntilDestroyed(this.destroyRef))
      .pipe(pairwise())
      .subscribe({
        next: ([warningDataOld, warningDataNew]) => {
          if (!warningDataOld.paymentMissing && warningDataNew.paymentMissing) {
            if (warningDataNew.customerAddressMissing) {
              this.showFinishRegistrationReminderDialog();
            } else {
              this.showPaymentMethodReminderDialog();
            }
          }
        },
      });
  }

  addDialog(dialog: Dialog): void {
    this.dialogues.next([...this.dialogues.value, dialog]);
  }

  removeDialog(index: number): void {
    const dialogs = this.dialogues.value;
    dialogs.splice(index, 1);
    this.dialogues.next(dialogs);
  }

  showPaymentMethodReminderDialog() {
    this.addDialog(this.dialogConfig.getPaymentMethodReminderDialog());
  }

  showCustomerAddressReminderDialog() {
    this.addDialog(this.dialogConfig.getCustomerAddressReminderDialog());
  }

  showFinishRegistrationReminderDialog() {
    this.addDialog(this.dialogConfig.getFinishRegistrationReminderDialog());
  }

  showBanImminentDialog() {
    this.addDialog(this.dialogConfig.getBanImminentDialog());
  }

  showPaymentFailedDialog() {
    this.addDialog(this.dialogConfig.getPaymentFailedDialog());
  }

  showMissingPaymentMethodBannedDialog() {
    this.addDialog(this.dialogConfig.getMissingPaymentMethodBannedDialog());
  }

  showBannedDialog() {
    this.addDialog(this.dialogConfig.getBannedDialog());
  }

  showWelcomeDialog(): void {
    this.addDialog(this.dialogConfig.getWelcomeDialog());
  }

  showAccountCompletionDialog(): void {
    this.addDialog(this.dialogConfig.getAccountCompletionDialog());
  }

  showPaymentMethodDepositFailedDialog(): void {
    this.addDialog(this.dialogConfig.getPaymentMethodDepositFailedDialog());
  }
}
