import { Component, DestroyRef, ElementRef, forwardRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { licensePlateCountries } from '../../../data/countries.data';
import { getCountryByLPRCode } from '../../../utils/license-plate.utils';
import { LicensePlate } from '../../../models/licensePlate';
import { fbString, validateLicensePlate } from '../../../utils/form.utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { pairwise } from 'rxjs';
import { LicensePlateCountry, LPRCountryCode } from '../../../models/country'; // Component decorator

// Component decorator
@Component({
  selector: 'arivo-license-plate-input',
  templateUrl: './arivo-license-plate-input.component.html',
  styleUrl: './arivo-license-plate-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ArivoLicensePlateInputComponent),
      multi: true,
    },
  ],
  host: {
    '(blur)': 'onTouched()',
  },
})

// Component class
export class ArivoLicensePlateInputComponent implements OnInit, ControlValueAccessor {
  // Input properties
  @Input() inline: boolean = false;
  @Input() showErrors: boolean = true;

  // ViewChild properties
  @ViewChild('licensePlateInput') licensePlateInput?: ElementRef<HTMLInputElement>;
  @ViewChild('licensePlateFirstInput') licensePlateFirstInput?: ElementRef<HTMLInputElement>;
  @ViewChild('licensePlateSecondInput') licensePlateSecondInput?: ElementRef<HTMLInputElement>;

  // Form group
  licensePlateForm: FormGroup;

  // Constants
  defaultCountry: LPRCountryCode = 'A';
  countries: LicensePlateCountry[] = licensePlateCountries;

  // Placeholder properties
  licensePlateInputPlaceholder = '';
  licensePlateFirstInputPlaceholder = '';
  licensePlateSecondInputPlaceholder = '';

  // Private properties
  private destroyRef = inject(DestroyRef);

  // Constructor
  constructor(private fb: FormBuilder) {
    this.licensePlateForm = this.fb.nonNullable.group(
      {
        licensePlateCountry: [<LPRCountryCode>'A', Validators.required],
        licensePlate: [fbString, Validators.required],
        licensePlateFirstPart: [fbString, Validators.required],
        licensePlateSecondPart: [fbString, Validators.required],
      },
      { validators: validateLicensePlate },
    );
  }

  ngOnInit() {
    this.subscribeToFormValueChanges();
  }

  get selectedCountry(): LicensePlateCountry {
    return getCountryByLPRCode(this.licensePlateForm.get('licensePlateCountry')?.value);
  }

  get licensePlateFirstPartString() {
    return this.licensePlateForm.get('licensePlateFirstPart')?.value ?? '';
  }

  get licensePlateSecondPartString() {
    return this.licensePlateForm.get('licensePlateSecondPart')?.value ?? '';
  }

  // Form control methods
  markAsTouched(controlName: string): void {
    this.licensePlateForm.get(controlName)?.markAsTouched();
    this.onTouched();
    this.updateLicensePlate();
  }

  markAllAsTouched() {
    this.licensePlateForm.markAllAsTouched();
  }

  // ControlValueAccessor interface methods
  onTouched: any = () => {};

  writeValue(licensePlateObject: LicensePlate): void {
    if (licensePlateObject) {
      this.licensePlateForm.patchValue({
        licensePlateCountry: licensePlateObject.license_plate_country ?? this.defaultCountry,
        licensePlate: licensePlateObject.license_plate ?? '',
        licensePlateFirstPart: licensePlateObject.license_plate?.split(':')[0] ?? '',
        licensePlateSecondPart: licensePlateObject.license_plate?.split(':')[1] ?? '',
      });
    }
  }

  registerOnChange(fn: (value: LicensePlate) => void): void {
    this.licensePlateForm.valueChanges.subscribe((value) => {
      const licensePlate: LicensePlate = {
        license_plate_country: value.licensePlateCountry,
        license_plate: value.licensePlate,
      };
      fn(licensePlate);
    });
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.licensePlateForm.disable() : this.licensePlateForm.enable();
  }

  // Helper methods
  updateLicensePlate() {
    if (this.selectedCountry.firstPartLength) {
      if (this.licensePlateFirstPartString === '' && this.licensePlateSecondPartString === '') {
        this.licensePlateForm.patchValue({ licensePlate: '' });
      } else {
        this.licensePlateForm.patchValue({
          licensePlate: `${this.licensePlateFirstPartString}:${this.licensePlateSecondPartString}`.toUpperCase(),
        });
      }
    }
  }

  setPlaceholders(countryCode: LPRCountryCode) {
    let placeholder = getCountryByLPRCode(countryCode).placeholder;
    this.licensePlateInputPlaceholder = placeholder;
    if (placeholder.includes(':')) {
      this.licensePlateFirstInputPlaceholder = placeholder.split(':')[0];
      this.licensePlateSecondInputPlaceholder = placeholder.split(':')[1];
    }
  }

  // Event handlers
  jumpToPreviousInput(event: KeyboardEvent): void {
    if (this.licensePlateSecondPartString.length === 0 && event.key === 'Backspace') {
      this.licensePlateFirstInput?.nativeElement.focus();

      // Remove the last character from the first input
      this.licensePlateForm.patchValue({
        licensePlateFirstPart: this.licensePlateFirstPartString,
      });
      this.licensePlateForm.get('licensePlateFirstPart')?.markAsDirty();
    }
  }

  jumpToNextInput(event: any): void {
    const key = event.data;

    if (!RegExp(/^[a-zA-ZäöüÄÖÜ1-9]$/).exec(key)) return;
    if (key === 'Delete' || key === 'Backspace') return;
    if (this.licensePlateFirstPartString.length >= this.selectedCountry.firstPartLength!) {
      if (document.activeElement === this.licensePlateFirstInput?.nativeElement) {
        this.licensePlateSecondInput?.nativeElement.focus();
        this.licensePlateSecondInput?.nativeElement.setSelectionRange(0, 0);
      }
    }
  }

  // Private methods
  private subscribeToFormValueChanges() {
    this.licensePlateForm
      .get('licensePlateFirstPart')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.updateLicensePlate();
      });

    this.licensePlateForm
      .get('licensePlateSecondPart')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.updateLicensePlate();
      });

    this.licensePlateForm
      .get('licensePlateCountry')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), pairwise())
      .subscribe(([oldVal, newVal]) => {
        this.setPlaceholders(newVal);
        // Change from multiple.firstPartLength to single part
        if (!getCountryByLPRCode(newVal).firstPartLength) {
          this.licensePlateForm.patchValue({ licensePlate: this.licensePlateForm.get('licensePlate')?.value.replace(':', '') ?? '' });
        }
        // Change from multiple.firstPartLength to multiple.firstPartLength
        else if (getCountryByLPRCode(oldVal).firstPartLength) {
          const oldLength = getCountryByLPRCode(oldVal).firstPartLength;
          const newLength = getCountryByLPRCode(newVal).firstPartLength;
          if (oldLength !== newLength) {
            const licensePlateParts = this.licensePlateForm.get('licensePlate')?.value.split(':') ?? ['', ''];
            const firstPart = licensePlateParts[0].substring(0, newLength);
            const secondPart = licensePlateParts[0].substring(newLength) + (licensePlateParts[1] ?? '');

            this.licensePlateForm.patchValue({
              licensePlate: `${firstPart}:${secondPart}`,
              licensePlateFirstPart: firstPart,
              licensePlateSecondPart: secondPart,
            });
          }
        }
        // Change from single part to multiple.firstPartLength
        else if (!getCountryByLPRCode(oldVal).firstPartLength) {
          if (this.licensePlateFirstPartString === '' && this.licensePlateSecondPartString === '') {
            this.licensePlateForm.patchValue({
              licensePlate: '',
            });
          } else {
            this.licensePlateForm.patchValue({
              licensePlate: `${this.licensePlateFirstPartString}:${this.licensePlateSecondPartString}`,
            });
          }
        }
      });
  }
}
