import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ArivoStepperComponent } from '../../../../shared/components/ui-components/arivo-stepper/arivo-stepper.component';
import { DialogService } from '../../../../shared/services/dialog.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-onboarding-finish-third-step',
  templateUrl: './onboarding-finish-third-step.component.html',
  styleUrl: './onboarding-finish-third-step.component.scss',
})
export class OnboardingFinishThirdStepComponent {
  @Input({ required: true }) stepperComponent?: ArivoStepperComponent;

  constructor(
    private _router: Router,
    private translateService: TranslateService,
    private dialogService: DialogService,
  ) {}

  previous(): void {
    if (this.stepperComponent) {
      this.stepperComponent.previous();
    }
  }

  next(): void {
    this.dialogService.showAccountCompletionDialog();
    this._router.navigateByUrl('/dashboard');
  }
}
