import { DestroyRef, inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ProviderSettingsService } from './provider-settings.service';
import { FeatureFlags } from '../../shared/models/providerSettings';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private destroyRef = inject(DestroyRef);
  private featureFlags: FeatureFlags = environment.featureFlags;
  constructor(private providerSettingsService: ProviderSettingsService) {
    this.providerSettingsService.providerSettings.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((settings) => {
      if (settings) {
        this.setFeatureFlags(settings.feature_flags);
      }
    });
  }

  private setFeatureFlags(featureFlags: FeatureFlags) {
    this.featureFlags = { ...this.featureFlags, ...featureFlags };
  }

  getFeatureFlag<K extends keyof FeatureFlags>(flag: K): FeatureFlags[K] {
    return this.featureFlags[flag] ?? undefined;
  }
}
