import { CanActivateFn, UrlTree } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';
import { inject } from '@angular/core';
import { StorageManagerService } from '../services/storage-manager.service';
import { FeatureFlagService } from '../services/feature-flag.service';
import { ToastService } from '../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../features/user/user.service';

export const checkTokenValidity: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const storageManagerService = inject(StorageManagerService);
  return of(!!storageManagerService.load('token'));
};

export const checkArivoUser: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const userService = inject(UserService);
  return userService.getUserResponse().pipe(
    map((user) => user.email.endsWith('@arivo.co')),
    catchError(() => of(false)),
  );
};

export const checkTokenInvalidity: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const storageManagerService = inject(StorageManagerService);
  return of(!storageManagerService.load('token'));
};

export const checkAccessCardEnabled: CanActivateFn = (): Observable<boolean | UrlTree> => {
  try {
    const featureFlagService = inject(FeatureFlagService);
    return of(featureFlagService.getFeatureFlag('door_opener_qr_code'));
  } catch (e) {
    return of(false);
  }
};

export const checkContractCreateEnabled: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const featureFlagService = inject(FeatureFlagService);
  const toastService = inject(ToastService);
  const translateService = inject(TranslateService);
  const allowContractCreation =
    featureFlagService.getFeatureFlag('enable_contract_creation_dashboard') ||
    featureFlagService.getFeatureFlag('enable_contract_creation_products');

  if (allowContractCreation) {
    return of(true);
  }
  toastService.add({
    type: 'error',
    title: translateService.instant('Contracts.Errors.ContractCreationDisabledTitle'),
    message: translateService.instant('Contracts.Errors.ContractCreationDisabledMessage'),
  });
  return of(false);
};
