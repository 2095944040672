import { Component, DestroyRef, inject, Inject, OnInit, ViewChild } from '@angular/core';
import { GarageContract } from '../../../shared/models/contract';
import { ActivatedRoute } from '@angular/router';
import { TypedRoute } from '../../../shared/utils/router.utils';
import { CoreData, DashboardResolve } from '../../../shared/models/routeTyping';
import { ArivoDialogComponent } from '../../../shared/components/ui-components/arivo-dialog/arivo-dialog.component';
import { HttpClient } from '@angular/common/http';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CoreDataService } from '../../../core/services/core-data.service';
import { formatAddress } from '../../../shared/utils/common.utils';
import { FeatureFlagService } from '../../../core/services/feature-flag.service';

@Component({
  selector: 'app-dashboard-contracts',
  templateUrl: './dashboard-contracts.component.html',
  styleUrl: './dashboard-contracts.component.scss',
})
export class DashboardContractsComponent implements OnInit {
  @ViewChild('qrDialog') qrDialog!: ArivoDialogComponent;
  private destroyRef = inject(DestroyRef);
  qrCode: null | string = null;
  hasPaymentMethod = false;
  creatingContractEnabled!: boolean;
  contractQrCodeEnabled!: boolean;
  qrImageError = false;

  contracts: GarageContract[] = [];

  constructor(
    @Inject(ActivatedRoute) private route: TypedRoute<DashboardResolve>,
    private coreDataService: CoreDataService,
    private http: HttpClient,
    private featureFlagService: FeatureFlagService,
  ) {}

  ngOnInit(): void {
    this.route.data.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data) => {
      this.contracts = data.dashboardApiResponse.garage_contracts;
    });
    this.coreDataService.coreData.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((coreData: CoreData) => {
      this.hasPaymentMethod = coreData.signup_status.has_payment_method;
    });
    this.creatingContractEnabled = this.featureFlagService.getFeatureFlag('enable_contract_creation_dashboard');
    this.contractQrCodeEnabled = this.featureFlagService.getFeatureFlag('enable_contract_qr_code');
  }

  isRpcFallbackGarage(contract: GarageContract): boolean {
    if (!contract.garage) {
      return false;
    }
    return (
      !!contract.garage.name && !contract.garage.city && !contract.garage.street && !contract.garage.number && !contract.garage.zip_code
    );
  }

  openQrDialog(id: GarageContract['id']) {
    this.qrCode = this.contracts.find((contract) => contract.id === id)?.qr_src ?? null;
    if (this.qrCode) {
      this.qrDialog.open = true;
      this.qrImageError = false;
    }
  }

  handleQrCodeError() {
    this.qrImageError = true;
  }

  dateIsInFuture(date: Date): boolean {
    return date > new Date();
  }

  protected readonly formatAddress = formatAddress;
}
