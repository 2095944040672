import { Component, DestroyRef, inject, Inject, Input, OnInit } from '@angular/core';
import { ArivoStepperComponent } from '../../../../shared/components/ui-components/arivo-stepper/arivo-stepper.component';
import { FormBuilder, Validators } from '@angular/forms';
import { ContractLicensePlate, LicensePlate } from '../../../../shared/models/licensePlate';
import { ContractCreateService, ContractTemplateInfo } from '../contract-create.service';
import { Garage } from '../../../../shared/models/garage';
import { ContractTemplate } from '../../../../shared/models/contractTemplate';
import { ActivatedRoute, Router } from '@angular/router';
import { TypedRoute } from '../../../../shared/utils/router.utils';
import { ContractCreateResolve } from '../../../../shared/models/routeTyping';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../../shared/services/toast.service';
import { ContractsApiService } from '../../contracts-api.service';
import { debounceTime, pairwise } from 'rxjs';
import { formatDateWithTimezone, parseDate, setLoading } from '../../../../shared/utils/common.utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ProviderLinkOptions } from '../../../../shared/models/providerSettings';
import { ProviderSettingsService } from '../../../../core/services/provider-settings.service';

@Component({
  selector: 'app-contract-create-second-step',
  templateUrl: './contract-create-second-step.component.html',
  styleUrl: './contract-create-second-step.component.scss',
})
export class ContractCreateSecondStepComponent implements OnInit {
  @Input() stepperComponent!: ArivoStepperComponent;
  destroyRef = inject(DestroyRef);

  garage?: Garage;
  product?: ContractTemplate;
  licensePlates: ContractLicensePlate[] = [];
  contractTemplateInfo: ContractTemplateInfo | null = null;
  loading = false;
  providerLinks!: ProviderLinkOptions;

  constructor(
    private router: Router,
    private _formBuilder: FormBuilder,
    private toastService: ToastService,
    private translateService: TranslateService,
    private contractsApiService: ContractsApiService,
    private contractCreateService: ContractCreateService,
    private providerSettingsService: ProviderSettingsService,
    @Inject(ActivatedRoute) private route: TypedRoute<ContractCreateResolve>,
  ) {}

  ngOnInit() {
    const licensePlates: ContractLicensePlate[] = this.route.snapshot.data.contractCreateApiResponse.license_plates.map(
      (licensePlate: LicensePlate): ContractLicensePlate => {
        return {
          ...licensePlate,
          enabled: false,
        };
      },
    );

    this.providerSettingsService.providerSettings.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.providerLinks = this.providerSettingsService.getLinks();
    });

    this.contractCreateService.garageSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((garage) => {
      if (this.garage !== garage) {
        this.garage = garage;

        this.licensePlates = licensePlates.map((licensePlate: LicensePlate): ContractLicensePlate => {
          return {
            ...licensePlate,
            enabled: false,
          };
        });

        this.contractForm.reset();
        this.contractForm.controls.licensePlates.reset();
        this.contractForm.controls.start.patchValue(new Date());
        this.contractForm.controls.parkingSpots.patchValue(1);
        this.contractForm.controls.licensePlates.patchValue([]);
      }
    });

    this.contractCreateService.productSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((product) => {
      this.product = product;
      if (this.product.initial_start && parseDate(this.product.initial_start) > new Date()) {
        this.contractForm.controls.start.patchValue(parseDate(this.product.initial_start));
      }
      this.updateContractTemplateInfo();
    });

    this.contractForm.valueChanges
      .pipe(pairwise(), debounceTime(200), takeUntilDestroyed(this.destroyRef))
      .subscribe(([oldVal, newVal]) => {
        if (oldVal && this.product && (oldVal.start !== newVal.start || oldVal.parkingSpots !== newVal.parkingSpots)) {
          this.updateContractTemplateInfo();
        }
      });
  }

  get totalPrice(): number {
    return this.contractTemplateInfo?.price.amount ?? 0;
  }

  contractForm = this._formBuilder.group({
    start: [new Date(), Validators.required],
    parkingSpots: [1, Validators.required],
    licensePlates: [<Array<LicensePlate>>[]],
    termsAndConditions: [false, [Validators.requiredTrue]],
  });

  startDatepickerFilter = (d: Date | null): boolean => {
    if (this.product?.initial_start && parseDate(this.product.initial_start) > new Date()) {
      const date = d || parseDate(this.product.initial_start);
      return parseDate(this.product.initial_start).setHours(0, 0, 0, 0) <= date.valueOf();
    } else {
      const date = d || new Date();
      return new Date().setHours(0, 0, 0, 0) <= date.valueOf();
    }
  };

  updateLicensePlates(licensePlates: LicensePlate[]) {
    this.contractForm.controls.licensePlates.patchValue(licensePlates);
    this.contractForm.controls.licensePlates.updateValueAndValidity();
  }

  updateContractTemplateInfo() {
    if (!this.product) {
      return;
    }
    this.contractCreateService
      .getContractTemplateInfo(
        this.product.id,
        this.contractForm.value.parkingSpots!,
        formatDateWithTimezone(this.contractForm.value.start!),
      )
      .pipe(setLoading(this))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((contractTemplateInfo) => {
        this.contractTemplateInfo = contractTemplateInfo;
      });
  }

  get isFirstOfMonth(): boolean {
    const startDate = this.contractForm.get('start')?.value;
    return startDate ? startDate.getDate() === 1 : false;
  }

  get proratedPrice(): number {
    return this.contractTemplateInfo?.first_payment_period.price.amount ?? 0;
  }

  finish() {
    this.contractForm.markAllAsTouched();
    this.contractForm.controls.licensePlates.updateValueAndValidity();

    const licensePlates = this.contractForm.value.licensePlates;

    if (this.contractForm.valid && this.garage && this.product) {
      const contract = {
        contract_template_id: this.product.id,
        group_limit: this.contractForm.value.parkingSpots!,
        license_plates: licensePlates!,
        start: formatDateWithTimezone(this.contractForm.value.start!),
        end: null,
      };

      this.contractsApiService
        .createContract(contract)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: () => {
            this.router.navigateByUrl('/dashboard').finally(() => {
              this.toastService.add({
                message: this.translateService.instant('Contracts.CreateContractSuccess'),
                type: 'success',
              });
            });
          },
          error: (error) => {
            if (error.status === 400 && error.error.detail) {
              const detail = error.error.detail;
              for (const key in detail) {
                if (detail.hasOwnProperty(key)) {
                  detail[key].forEach((message: string) => {
                    this.toastService.add({
                      message: message,
                      type: 'error',
                    });
                  });
                }
              }
            } else {
              this.toastService.add({
                message: this.translateService.instant('Contracts.UpdateContractError'),
                type: 'error',
              });
            }
          },
        });
    }
  }
}
