import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { NavigationService } from '../../../../core/services/navigation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthService } from '../../../../core/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  navigationOpen: boolean = false;

  constructor(
    private navigationService: NavigationService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.navigationService.navigationOpenStateSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((open) => {
      this.navigationOpen = open;
    });
  }

  openNavigation(): void {
    this.navigationService.navigationOpenStateSubject.next(true);
  }

  onLogOutClicked(): void {
    this.authService.logOut();
  }
}
