import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'localizedCurrency',
  pure: false,
})
export class LocalizedCurrencyPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: string | number, currencyCode: string = 'EUR', digitInfo?: string): string | null {
    const currencyPipe: CurrencyPipe = new CurrencyPipe(this.translateService.currentLang);
    return currencyPipe.transform(value, currencyCode, 'symbol', digitInfo);
  }
}
