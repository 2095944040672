import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en-GB';
import { StorageManagerService } from './storage-manager.service';
import { AvailableLanguage, Language } from '../utils/common.data';
import { BehaviorSubject, EMPTY, Observable, switchMap } from 'rxjs';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { ProviderSettingsService } from './provider-settings.service';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  languageChanged: BehaviorSubject<Language> = new BehaviorSubject<Language>(this.getCurrentLanguage());

  private static readonly _availableLanguages: AvailableLanguage[] = [
    {
      language: 'en',
      title: 'English',
      ngLocale: localeEn,
    },
    {
      language: 'de',
      title: 'Deutsch',
      ngLocale: localeDe,
    },
  ];

  private readonly _languageStorageKey = 'language';
  private readonly defaultLanguage: Language = 'en';

  constructor(
    private providerSettingsService: ProviderSettingsService,
    private translateService: TranslateService,
    private storageManagerService: StorageManagerService,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    private _adapter: DateAdapter<any>,
  ) {}

  private isAvailableLanguage(languageToTest: string | undefined): boolean {
    if (!languageToTest) {
      return false;
    }
    return LanguageService._availableLanguages.some((lang) => lang.language === languageToTest);
  }

  private getAvailableLanguage(language: Language): AvailableLanguage {
    return LanguageService._availableLanguages.find((lang) => lang.language === language)!;
  }

  public registerAndInitializeLocales(): Observable<void> {
    const storedLanguage: string | null = this.storageManagerService.load(this._languageStorageKey);
    const browserLanguage: string | undefined = this.translateService.getBrowserLang();
    const userLanguage = storedLanguage ?? browserLanguage;

    return this.registerLanguage(this.isAvailableLanguage(userLanguage) ? (userLanguage as Language) : this.defaultLanguage, false);
  }

  public registerLanguage(language: Language, fetchProviderSettings: boolean = true): Observable<void> {
    const languageData: AvailableLanguage = this.getAvailableLanguage(language);

    registerLocaleData(languageData.ngLocale, languageData.language);
    return this.translateService.getTranslation(languageData.language).pipe(
      switchMap(() => {
        this.setLanguage(language, fetchProviderSettings);
        return EMPTY;
      }),
    );
  }

  public getCurrentLanguage(): Language {
    return this.translateService.currentLang as Language;
  }

  public getAdyenLocale(): string {
    return this.getCurrentLanguage() === 'en' ? 'en-US' : 'de-DE';
  }

  public getRegisteredLanguages(): Language[] {
    return this.translateService.getLangs() as Language[];
  }

  public setLanguage(language: Language, fetchProviderSettings: boolean = true): void {
    if (language === this.getCurrentLanguage()) return;

    if (this.getRegisteredLanguages().includes(language)) {
      if (fetchProviderSettings) {
        this.storeLanguage(language);
        this.providerSettingsService.fetchProviderSettings().subscribe((response) => {
          this.providerSettingsService.setProviderSettings(response);
          this.translateService.use(language);
          this._adapter.setLocale(language);
        });
      } else {
        this.translateService.use(language);
        this._adapter.setLocale(language);
        this.storeLanguage(language);
      }
    } else {
      this.registerLanguage(language, fetchProviderSettings).subscribe(() => {});
    }
  }

  storeLanguage(language: Language) {
    if (this.languageChanged.value !== language) this.languageChanged.next(language);
    this.storageManagerService.store(this._languageStorageKey, language);
  }
}
