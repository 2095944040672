<div class="card">
  <div class="card-header">
    <div class="headline-large">Geschäfts- und Nutzungsbedingungen</div>
    <div class="headline-x-small">Version 1 (01.04.2025)</div>
  </div>
  <div class="card-body">
    <div class="body-medium space-y-1">
      <div class="cursor-pointer hover:text-interaction-light text-interaction" (click)="scrollToHeader('portal-terms-header')">
        <i class="fa fal fa-arrow-up-right-from-square mr-2"></i>Nutzungsbedingungen für das Arivo Customer Portal
      </div>
      <div class="cursor-pointer hover:text-interaction-light text-interaction" (click)="scrollToHeader('contract-terms-header')">
        <i class="fa fal fa-arrow-up-right-from-square mr-2"></i>Geschäftsbedingungen für Dauerparker
      </div>
      <div class="cursor-pointer hover:text-interaction-light text-interaction" (click)="scrollToHeader('ppu-terms-header')">
        <i class="fa fal fa-arrow-up-right-from-square mr-2"></i>Geschäftsbedingungen für Parken nach Zeit
      </div>
      <div class="cursor-pointer hover:text-interaction-light text-interaction" (click)="scrollToHeader('garage-terms-header')">
        <i class="fa fal fa-arrow-up-right-from-square mr-2 text-md"></i>Nutzungsbedingungen auf der Parkfläche (für Dauerparker und Parken
        nach Zeit)
      </div>
    </div>
  </div>

  <div class="card-body">
    <div class="headline-small mb-5" id="portal-terms-header">Nutzungsbedingungen für das Arivo Customer Portal</div>
    <div class="body-medium">
      <ol>
        <li class="font-bold text-lg mb-3">Geltungsbereich</li>
        <ol>
          <li>
            Diese Nutzungsbedingungen regeln das Verhältnis zwischen dem Parkplatznutzer (Kunde) und dem Parkflächenbetreiber bei der
            Nutzung des Arivo Customer Portals. Die Nutzungsbedingungen sind am Arivo Customer Portal abrufbar und können abgespeichert oder
            ausgedruckt werden.
          </li>
          <li>
            Mit dem Arivo Customer Portal haben Kunden die Möglichkeit, ein Konto anzulegen, Parken nach Zeit zu nutzen oder selbständig
            einen Dauerparkplatz zu buchen. Der Kunde kann selbständig Kennzeichen hinzufügen / entfernen / aktivieren / deaktivieren,
            Rechnungen herunterladen, Zahlungsmittel hinzufügen / ändern / entfernen, Parkvorgänge einsehen, Dauerparkplätze verwalten und
            seine Kontaktdaten ändern.
          </li>
          <li>
            Dem Nutzer wird das Recht auf Nutzung des Arivo Customer Portals nach Maßgabe dieser Nutzungsbedingungen eingeräumt. Abweichende
            Regelungen werden nicht anerkannt und auch nicht zum Vertragsinhalt.
          </li>
        </ol>
        <li class="font-bold text-lg mb-3">Zulässige Nutzung und Urheberrecht</li>
        <ol>
          <li>
            Die Nutzung des Arivo Customer Portals ist ausschließlich für rechtmäßige Zwecke gestattet. Insbesondere ist es untersagt:
            <ul>
              <li>Inhalte der Webseite ohne Erlaubnis zu vervielfältigen, zu verändern oder weiterzuverbreiten,</li>
              <li>die Webseite oder deren Dienste missbräuchlich zu nutzen (z. B. durch Hacking oder Spam),</li>
              <li>falsche oder irreführende Angaben bei der Nutzung der Webseite zu machen.</li>
            </ul>
          </li>
          <li>
            Alle Inhalte dieser Webseite, einschließlich Texte, Bilder, Logos und Designs, sind urheberrechtlich geschützt. Jede
            unautorisierte Verwendung ist untersagt.
          </li>
        </ol>
        <li class="font-bold text-lg mb-3">Leistungsänderung, Änderungen der Nutzungsbedingungen</li>
        <ol>
          <li>
            Der Parkflächenbetreiber behält sich das Recht vor, das Arivo Customer Portal und/oder die über das Arivo Customer Portal zur
            Verfügung gestellten Leistungen einseitig anzupassen, soweit dies erforderlich ist.
          </li>
          <li>
            Der Parkflächenbetreiber behält sich das Recht vor, diese Nutzungsbedingungen jederzeit zu ändern. Die jeweils aktuelle Version
            ist auf dieser Webseite verfügbar. Der Kunde wird über eine Änderung der Nutzungsbedingungen per E-Mail informiert. Durch die
            fortgesetzte Nutzung der Webseite nach Änderungen stimmen Sie den aktualisierten Bedingungen zu.
          </li>
        </ol>
        <li class="font-bold text-lg mb-3">Registrierung durch den Kunden selbst</li>
        <ol>
          <li>Um das Arivo Customer Portal zu nutzen, muss der Kunde sich für dieses registrieren.</li>
          <li>Kunden können mittels Internetbrowser auf das Arivo Customer Portal zugreifen und ein Kundenkonto anlegen.</li>
          <li>
            Nach Eingabe eines Kennzeichens, einer E-Mail-Adresse und Akzeptieren der Bedingungen wird ein Link zur Aktivierung und
            Vervollständigung des Profils an die angebende E-Mail-Adresse verschickt.
          </li>
          <li>
            Um das Profil zu vervollständigen, muss der Kunde folgende Informationen hinzufügen:
            <ul>
              <li>Hinterlegen eines Zahlungsmittels (SEPA-Lastschrift, Kreditkarte, Google Pay und Apple Pay)</li>
              <li>Vervollständigen der Kontaktdaten</li>
              <li>Hinterlegen von Kennzeichen</li>
            </ul>
          </li>
        </ol>
        <li class="font-bold text-lg mb-3">Registrierung durch Admin</li>
        <ol>
          <li>Der Parkflächenbetreiber bzw. der Admin kann einen Kunden im Backoffice anlegen.</li>
          <li>Der Kunde erhält im Anschluss eine E-Mail zur Aktivierung seines Kontos.</li>
          <li>Mit Klick auf Aktivieren und Akzeptieren der Geschäftsbedingungen ist das Kundenkonto aktiv.</li>
          <li>Im Anschluss kann der Kunde ein Profil vervollständigen.</li>
          <li>
            Um das Profil zu vervollständigen, muss der Kunde folgende Informationen hinzufügen:
            <ul>
              <li>Hinterlegen eines Zahlungsmittels (SEPA-Lastschrift, Kreditkarte, Google Pay und Apple Pay)</li>
              <li>Vervollständigen der Kontaktdaten</li>
              <li>Hinterlegen von Kennzeichen</li>
            </ul>
          </li>
        </ol>
        <li class="font-bold text-lg mb-3">Login</li>
        <ol>
          <li>
            Der Login in das Arivo Customer Portal erfolgt mittels Zusendung eines Einmal-Login-Links an die hinterlegte E-Mail-Adresse. Der
            Kunde trägt die alleinige Verantwortung, seinen E-Mail-Account vor Zugriffen durch Dritte zu schützen. Eine vollumfängliche
            Funktion des Arivo Customer Portals ist nach Vervollständigung des Profils möglich. Nach Login in das Arivo Customer Portal mit
            E-Mail-Adresse und Einmal-Login-Link ist der Kunde bis zum Ausloggen bzw. bis zum Löschen des im Browser hinterlegten
            Session-Cookies eingeloggt. Das Löschen des Cookies kann je nach Browsereinstellung auch automatisch, zB durch Schließen des
            Browserfensters erfolgen. Cookie-Einstellungen können auch in den Browsereinstellungen des jeweiligen Browsers angepasst werden.
          </li>
        </ol>
        <li class="font-bold text-lg mb-3">Vertragsabschluss</li>
        <ol>
          <li>
            Mit Abschluss der Registrierung kommt ein gültiger Vertrag zwischen dem Parkflächenbetreiber und dem Kunden über die Nutzung des
            Arivo Customer Portals zustande.
          </li>
          <li>Die Nutzung des Arivo Customer Portals wird auf unbestimmte Dauer abgeschlossen.</li>
          <li>
            Der Kunde sichert zu, dass die von ihm gemachten Angaben korrekt und vollständig sind und er über die von ihm angegebenen Daten
            wie insbesondere Kennzeichen verfügungsbefugt ist.
          </li>
          <li>
            Die Registrierung für eine juristische Person darf nur von einer ausreichend vertretungsbefugten natürlichen Person vorgenommen
            werden. Diese vertretungsbefugte Person ist namentlich anzugeben und haftet dem Parkflächenbetreiber gegenüber für die
            ausreichende Vertretungsbefugnis.
          </li>
          <li>
            Der Parkflächenbetreiber behält sich das Recht vor, bei der Registrierung für das Arivo Customer Portal den Kunden nach freiem
            Ermessen abzulehnen, wobei von diesem Recht insbesondere bei falschen, missbräuchlich verwendeten oder irreführenden Angaben
            Gebrauch machen wird.
          </li>
          <li>
            Die Nutzung des Serviceportals ist kostenlos. Der Kunde hat für die Nutzung des Arivo Customer Serviceportals ein geeignetes
            Endgerät, eine gültige E-Mail-Adresse und eine Internetverbindung beizustellen. Die Kosten für den Datentransfer hat der Kunde
            selbst zu tragen.
          </li>
        </ol>
        <li class="font-bold text-lg mb-3">Benachrichtigungen</li>
        <ol>
          <li>
            Vertragsrelevante Erklärungen können an die vom Kunden zuletzt bekanntgegebene E-Mail-Adresse zugestellt werden. Der Kunde hat
            seine E-Mail-Adresse über die gesamte Vertragsdauer aktuell zu halten. Verletzt der Kunde diese Pflicht, gelten an die zuletzt
            bekanntgegebene E-Mail-Adresse zugestellte Erklärungen als dem Kunden wirksam zugegangen.
          </li>
        </ol>
        <li class="font-bold text-lg mb-3">Haftung und Gewährleistung</li>
        <ol>
          <li>
            Die Haftung für Vertragsverletzungen aufgrund der Nutzung des Arivo Customer Portals durch den Kunden ist (aufgrund des
            unentgeltlichen Rechtsgeschäfts) – ausgenommen bei Personenschäden - auf vorsätzliches und grob fahrlässiges Verhalten
            beschränkt. Diese Haftungsbeschränkung gilt auch für die Verletzung vertraglicher Nebenleistungs- und Schutzpflichten.
          </li>
          <li>
            Der Kunde stellt den Parkflächenbetreiber von jeglichen Ansprüchen frei, welche auf eine widerrechtliche oder diesen Bedingungen
            widersprechende Nutzung des Arivo Customer Portals zurückzuführen ist.
          </li>
          <li>
            Aufgrund der Unentgeltlichkeit des Arivo Customer Portals kommen hierauf keine gesetzlichen Gewährleistungsbestimmungen zur
            Anwendung.
          </li>
          <li>
            Der Parkflächenbetreiber übernimmt keine Gewähr, für die Richtigkeit, Vollständigkeit oder Aktualität der bereitgestellten
            Inhalte.
          </li>
          <li>
            Die Nutzung der Webseite erfolgt auf eigene Gefahr. Für Schäden, die durch die Nutzung oder Nichtverfügbarkeit der Webseite
            entstehen, wird keine Haftung übernommen.
          </li>
        </ol>
        <li class="font-bold text-lg mb-3">Anwendbares Recht & Gerichtsstand</li>
        <ol>
          <li>Diese Nutzungsbedingungen unterliegen dem Recht des Landes, in dem der Parkflächenbetreiber seinen Firmensitz hat.</li>
          <li>
            Der Gerichtsstand für alle Streitigkeiten im Zusammenhang mit der Nutzung des Arivo Customer Portals richtet sich nach den
            geltenden gesetzlichen Bestimmungen.
          </li>
        </ol>
        <li class="font-bold text-lg mb-3">Salvatorische Klausel</li>
        <ol>
          <li>
            Für Unternehmer gilt, sollten Bestimmungen dieser Nutzungsbedingungen ganz oder teilweise unwirksam, ungültig oder
            undurchsetzbar sein oder werden, so wird dadurch die Wirksamkeit, Gültigkeit oder Durchsetzbarkeit aller übrigen Bestimmungen
            nicht berührt. In einem solchen Fall gilt eine solche Bestimmung als vereinbart, die der Zielsetzung der ursprünglichen
            Bestimmung möglichst nahekommt und nicht unwirksam, ungültig oder undurchsetzbar ist.
          </li>
        </ol>
      </ol>
    </div>
  </div>
  <div class="card-body">
    <div class="headline-small mb-5" id="contract-terms-header">Geschäftsbedingungen für Dauerparker</div>
    <div class="body-medium">
      <ol>
        <li class="font-bold text-lg mb-3">Allgemeine Bestimmungen</li>
        <ol>
          <li class="font-bold">
            Die folgenden Bedingungen gelten explizit für Dauerparker. Zusätzlich finden die
            <span class="cursor-pointer hover:text-interaction-light text-interaction" (click)="scrollToHeader('garage-terms-header')">
              Nutzungsbedingungen auf der Parkfläche
            </span>
            Anwendung.
          </li>
          <li>
            Die Arivo GmbH fungiert lediglich als Auftragsverarbeiter gemäß
            <a
              class="cursor-pointer hover:text-interaction-light text-interaction no-underline"
              href="https://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32016R0679"
              target="_blank"
            >
              Art 4 Z 8 DSGVO
            </a>
            und ist nicht Betreiber der Parkfläche.
          </li>
          <li>Jeder Kunde unterwirft sich mit Abschluss des Nutzungsvertrages diesen Bedingungen.</li>
        </ol>
        <li class="font-bold text-lg mb-3">Vertragsabschluss und Verwaltung über das Arivo Customer Portal</li>
        <ol>
          <li class="font-bold">
            Mit Klick auf den Button „kostenpflichtig buchen“ im Arivo Customer Service Portal gibt der Kunde ein verbindliches Angebot auf
            Abschluss eines Dauerparkvertrages ab. Mit Zusendung der Buchungsdetails durch den Parkflächenbetreiber an die hinterlegte
            E-Mail-Adresse des Kunden kommt ein rechtsgültiger Vertrag zustande.
          </li>
          <li>
            Die Parkberechtigung bezieht sich auf das im Arivo Customer Portal hinterlegte Kennzeichen. Der Kunde sichert zu, über dieses
            Kennzeichen verfügungsberechtigt zu sein. Das Kennzeichen kann im Arivo Customer Portal jederzeit auf ein anderes
            (verfügungsberechtigtes) Kennzeichen geändert werden. Es dürfen nicht mehr als die vertraglich vereinbarten Stellplätze in
            Anspruch genommen werden.
          </li>
          <li>
            Der Parkflächenbetreiber ist berechtigt, diese Vertragsbestimmungen anzupassen. Änderungen der Vertragsbestimmungen werden dem
            Kunden per E-Mail mitgeteilt. Widerspricht der Kunde nicht binnen 30 Tagen ab Zugang, so gelten die geänderten
            Vertragsbestimmungen als angenommen. Der Kunde ist im Falle einer Änderung der Vertragsbestimmungen berechtigt, den Vertrag bis
            zum Inkrafttreten der neuen Vertragsbestimmungen zu beenden.
          </li>
          <li>
            Änderungen oder Ergänzungen der Kontaktdaten und Änderungen der Fahrzeugdaten sind umgehend im Arivo Service Portal zu
            hinterlegen. Kosten, die aufgrund nichtbekanntgegebener Änderungen entstehen, werden dem Kunden in Rechnung gestellt.
          </li>
        </ol>
        <li class="font-bold text-lg mb-3">Tarife, Abrechnung, Betriebszeiten</li>
        <ol>
          <li>
            Die jeweils gültigen Tarife und die Betriebszeiten sind dem Arivo Service Portal sowie dem Aushang unmittelbar bei der Einfahrt
            zu entnehmen.
          </li>
          <li>Einfahrt, Ausfahrt sowie Zutritt sind grundsätzlich nur mittels Einfahrtsberechtigung möglich.</li>
          <li>
            Die Software von Arivo erstellt monatlich eine Rechnung über die angefallenen Parkgebühren und diese werden in weiterer Folge
            durch einen Paymentprovider eingezogen. Der Kunde kann aus unterschiedlichen Zahlungsmethoden wählen (Apple Pay, Google Pay,
            Debit/Kreditkarte, SEPA-Lastschrift).
          </li>
          <li>Die Abrechnung für Dauerparker erfolgt <u>monatlich im Vorhinein</u>.</li>
          <li>Eine allfällige Rechtsgeschäftsgebühr trägt der Kunde.</li>
        </ol>
        <li class="font-bold text-lg mb-3">Vertragsdauer und Kündigung</li>
        <ol>
          <li>Die Vertragsdauer und Kündigungsfristen sind dem Vertrag zu entnehmen und im Arivo Customer Portal ersichtlich.</li>
          <li>
            Der Kunde hat die Möglichkeit den Vertrag im Arivo Customer Service Portal selbständig gemäß den geltenden Fristen zu kündigen.
          </li>
          <li>
            Der Parkflächenbetreiber ist berechtigt, das im Serviceportal hinterlegte Kennzeichen sowie die Nutzung der Produkte im
            Kundenkonto zu sperren, sofern eine ordnungsgemäße Bezahlung nicht durchgeführt werden konnte (z.B. kein gültiges Zahlungsmittel
            im Arivo Customer Portal hinterlegt, kein Einzug vom hinterlegten Zahlungsmittel möglich).
          </li>
          <li>
            Das Recht der Vertragsparteien, das zwischen ihnen bestehende Vertragsverhältnis aus wichtigem Grund frist- und terminlos zu
            kündigen, bleibt jeweils unberührt.
          </li>
          <li>
            Der Parkflächenbetreiber behält sich insbesondere das Recht vor, das Vertragsverhältnis bei einem schwerwiegenden Verstoß gegen
            diese Bedingungen, Betrug oder Betrugsversuch, einer missbräuchlichen Verwendung des Arivo Customer Portals, bei schuldhaften
            Falschangaben oder belästigendem oder dem Unternehmen schadenden Verhalten fristlos zu beenden.
          </li>
          <li>
            Im Falle eines verschuldeten schwerwiegenden Verstoßes gegen diese Bedingungen oder einer missbräuchlichen Verwendung des Arivo
            Customer Portals behält sich der Parkflächenbetreiber die Geltendmachung sämtlicher ihr dadurch entstandener Schäden vor.
          </li>
        </ol>
        <li class="font-bold text-lg mb-3">Erfüllungsort, Anwendbares Recht, Gerichtsstand</li>
        <ol>
          <li>Erfüllungsort ist die im Vertrag angegebene Parkfläche.</li>
          <li>Diese Bedingungen unterliegen dem Recht des Landes, in dem der Parkflächenbetreiber seinen Firmensitz hat.</li>
          <li>
            Der Gerichtsstand für alle Streitigkeiten im Zusammenhang mit diesen Bedingungen richtet sich nach den geltenden gesetzlichen
            Bestimmungen.
          </li>
        </ol>
      </ol>
    </div>
  </div>
  <div class="card-body">
    <div class="headline-small mb-5" id="ppu-terms-header">Geschäftsbedingungen für Parken nach Zeit</div>
    <div class="body-medium">
      <ol>
        <li class="font-bold text-lg mb-3">Allgemeine Bestimmungen</li>
        <ol>
          <li class="font-bold">
            Die folgenden Bedingungen gelten explizit für Parken nach Zeit Nutzer (registrierte Kurzparker). Zusätzlich finden die
            <span class="cursor-pointer hover:text-interaction-light text-interaction" (click)="scrollToHeader('garage-terms-header')">
              Nutzungsbedingungen auf der Parkfläche
            </span>
            Anwendung.
          </li>
          <li>
            Die Arivo GmbH fungiert lediglich als Auftragsverarbeiter gemäß
            <a
              class="cursor-pointer hover:text-interaction-light text-interaction no-underline"
              href="https://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32016R0679"
              target="_blank"
            >
              Art 4 Z 8 DSGVO
            </a>
            und ist nicht Betreiber der Parkfläche.
          </li>
          <li>
            Mit Parken nach Zeit, können Kunden auf allen dafür vorgesehenen Parkflächen des Betreibers schnell und unkompliziert mit dem im
            Arivo Customer Portal hinterlegten Zahlungsmittel gemäß der vor Ort gültigen Tarif- und Parkplatzordnung parken.
          </li>
          <li>Jeder Kunde unterwirft sich mit Nutzung von Parken nach Zeit diesen Bedingungen.</li>
        </ol>
        <li class="font-bold text-lg mb-3">Parken nach Zeit aktivieren</li>
        <ol>
          <li>Die Nutzung von Parken nach Zeit setzt die Registrierung für das Arivo Customer Portal voraus.</li>
          <li>
            Um Parken nach Zeit zu aktivieren, muss der Kunde sich im Arivo Customer Portal einloggen.<b>
              Mit erstmaliger Aktivierung in der Rubrik „Parken nach Zeit“ durch Klick auf den Button „Parken nach Zeit aktivieren“ und
              Zustimmung der Geschäftsbedingungen kommt ein gültiger Vertrag zustande.
            </b>
          </li>
          <li>
            Der Kunde sichert zu, dass die von ihm gemachten Angaben korrekt und vollständig sind und er über das angegebene Kennzeichen
            verfügungsberechtigt ist. Der Kunde sichert weiters zu, die betreffenden Daten die gesamte Vertragsdauer über aktuell zu halten.
          </li>
          <li>
            Für die Registrierung für Parken nach Zeit fallen keine Kosten an, es sind ausschließlich die in Folge jeweils entstandenen
            Parkgebühren zu bezahlen.
          </li>
          <li>
            Die Registrierung für eine juristische Person darf nur von einer ausreichend vertretungsbefugten natürlichen Person vorgenommen
            werden. Diese vertretungsbefugte Person ist namentlich anzugeben und haftet dem Parkflächenbetreiber gegenüber für die
            ausreichende Vertretungsbefugnis.
          </li>
          <li>
            Der Parkflächenbetreiber behält sich das Recht vor, Registrierungen für Parken nach Zeit nach freiem Ermessen abzulehnen,
            insbesondere wenn von falschen, missbräuchlich verwendeten oder irreführenden Angaben Gebrauch gemacht wird.
          </li>
          <li>
            Das hinterlegte Kennzeichen gilt als Identifikationsmerkmal für Parken nach Zeit. Beim Ausfahren wird die Parkgebühr automatisch
            berechnet und der Zahlungsvorgang mit dem hinterlegten Zahlungsmittel eingeleitet.
          </li>
        </ol>
        <li class="font-bold text-lg mb-3">Tarife, Abrechnung, Betriebszeiten</li>
        <ol>
          <li>
            Die jeweils gültigen Tarife und die Betriebszeiten sind dem Arivo Service Portal sowie dem Aushang unmittelbar bei der Einfahrt
            zu entnehmen.
          </li>
          <li>
            Die Software von Arivo erstellt monatlich eine Rechnung über die angefallenen Parkgebühren und diese werden in weiterer Folge
            durch einen Paymentprovider eingezogen. Der Kunde kann aus unterschiedlichen Zahlungsmethoden wählen (Apple Pay, Google Pay,
            Debit/Kreditkarte, SEPA-Lastschrift).
          </li>
          <li>Die Abrechnung für Parken nach Zeit erfolgt <u>monatlich im Nachhinein</u>.</li>
        </ol>
        <li class="font-bold text-lg mb-3">Vertragsdauer und Kündigung</li>
        <ol>
          <li>Dieses Vertragsverhältnis wird auf unbestimmte Zeit geschlossen.</li>
          <li>
            Der Kunde kann durch Schließung des Serviceportals den Vertrag auflösen. Alternativ kann er sein Kennzeichen in der Rubrik
            Parken nach Zeit deaktivieren.
          </li>
          <li>
            Der Parkflächenbetreiber ist berechtigt, das im Serviceportal hinterlegte Kennzeichen sowie die Nutzung der Produkte im
            Kundenkonto zu sperren, sofern eine ordnungsgemäße Bezahlung nicht durchgeführt werden konnte (z.B. kein gültiges Zahlungsmittel
            im Arivo Customer Portal hinterlegt, kein Einzug vom hinterlegten Zahlungsmittel möglich).
          </li>
          <li>
            Das Recht der Vertragsparteien, das zwischen ihnen bestehende Vertragsverhältnis aus wichtigem Grund frist- und terminlos zu
            kündigen, bleibt jeweils unberührt.
          </li>
          <li>
            Der Parkflächenbetreiber behält sich insbesondere das Recht vor, das Vertragsverhältnis bei einem schwerwiegenden Verstoß gegen
            diese Bedingungen, Betrug oder Betrugsversuch, einer missbräuchlichen Verwendung des Arivo Customer Portals, bei schuldhaften
            Falschangaben oder belästigendem oder dem Unternehmen schadenden Verhalten fristlos zu beenden.
          </li>
          <li>
            Im Falle eines verschuldeten schwerwiegenden Verstoßes gegen diese Bedingungen oder einer missbräuchlichen Verwendung des Arivo
            Customer Portals behält sich der Parkflächenbetreiber die Geltendmachung sämtlicher ihr dadurch entstandener Schäden vor.
          </li>
        </ol>
        <li class="font-bold text-lg mb-3">Vertragsdauer und Kündigung</li>
        <ol>
          <li>Erfüllungsort ist die im Vertrag angegebene Parkfläche.</li>
          <li>Diese Bedingungen unterliegen dem Recht des Landes, in dem der Parkflächenbetreiber seinen Firmensitz hat.</li>
          <li>
            Der Gerichtsstand für alle Streitigkeiten im Zusammenhang mit diesen Bedingungen richtet sich nach den geltenden gesetzlichen
            Bestimmungen.
          </li>
        </ol>
      </ol>
    </div>
  </div>
  <div class="card-body">
    <div class="headline-small mb-5" id="garage-terms-header">Nutzungsbedingungen auf der Parkfläche</div>
    <div class="body-medium">
      <ol>
        <li class="font-bold text-lg mb-3">Parkplatzordnung</li>
        <ol>
          <li>
            Zur Aufrechterhaltung eines reibungslosen Betriebsablaufes gilt die auf der Parkfläche ausgehängte Parkplatzordnung und die
            Aushänge vor Ort in der jeweils gültigen Fassung.
          </li>
        </ol>
        <li class="font-bold text-lg mb-3">Vertragsgegenstand</li>
        <ol>
          <li>
            Mit Abschluss des Nutzungsvertrages erhält der Kunde die Berechtigung, ein betriebs- und verkehrssicheres Fahrzeug auf einem
            markierten, freien und geeigneten Einstellplatz abzustellen; im Falle eines Bestehens von Beschränkungen (z.B. Reservierungen,
            beschränkte Abstelldauer) sind diese immer strikt zu beachten. Gekennzeichnete Behindertenabstellplätze dürfen ausschließlich
            von Behinderten mit gültigem, gut sichtbarem Parkausweis für Behinderte benützt werden.
          </li>
          <li>
            Auf der Parkfläche gilt sinngemäß die länderspezifische Straßenverkehrsordnung in der jeweils gültigen Fassung. Außerdem ist die
            vorgeschriebene Geschwindigkeitsbeschränkung einzuhalten.
          </li>
          <li>
            Die Bewachung und Verwahrung des Fahrzeuges, seines Zubehörs sowie allfälliger im Fahrzeug befindlicher Gegenstände oder mit dem
            Fahrzeug in die Parkfläche eingebrachter Sachen ist nicht Vertragsgegenstand.
          </li>
        </ol>
        <li class="font-bold text-lg mb-3">Haftung</li>
        <ol>
          <li>
            Der Parkflächenbetreiber und Arivo haften keinesfalls für das Verhalten Dritter (z.B. Diebstahl, Einbruch, Beschädigung)
            unabhängig davon, ob sich diese Dritten befugt oder unbefugt im Betriebsstandort aufhalten.
          </li>
          <li>Der Betreiber und Arivo haften nicht für Schäden, die mittelbar oder unmittelbar durch höhere Gewalt entstehen.</li>
          <li>
            Der Kunde ist verpflichtet, das abgestellte Fahrzeug ordnungsgemäß zu sichern und abzuschließen sowie unverzüglich den
            Betriebsstandort zu verlassen.
          </li>
          <li>Den Anordnungen des Parkflächenpersonals ist im Interesse eines reibungslosen Betriebes Folge zu leisten.</li>
          <li>
            Eventuelle Beschädigungen von Parkflächeneinrichtungen oder an anderen Fahrzeugen durch den Kunden sind unverzüglich und vor der
            Ausfahrt dem Parkflächenbetreiber zu melden; ebenso festgestellte Schäden am eigenen Fahrzeug. Gesetzliche Meldepflichten
            bleiben davon unberührt.
          </li>
        </ol>
        <li class="font-bold text-lg mb-3">Abstellen des Fahrzeuges</li>
        <ol>
          <li>
            Das Fahrzeug ist innerhalb der dafür gekennzeichneten Stellflächen so abzustellen, dass Dritte weder behindert noch anderweitig
            gewidmete Stellflächen unberechtigt benützt werden wie z. B. Behindertenparkplatz, sonstige reservierte Stellflächen, etc.;
            widrigenfalls ist der Betreiber zur Verrechnung einer Vertragsstrafe laut Aushang berechtigt.
          </li>
          <li>
            Für den Fall, dass
            <ul>
              <li>ein Fahrzeug vertragswidrig oder verkehrsbehindernd abgestellt wird;</li>
              <li>ein Fahrzeug gänzlich außerhalb eines markierten Stellplatzes abgestellt wird;</li>
              <li>ein Fahrzeug mehr als einen markierten Stellplatz verstellt;</li>
              <li>die zulässige Ladezeit oder Abstelldauer überschritten wird;</li>
            </ul>
            ist der Parkflächenbetreiber berechtigt, das Fahrzeug auf einen ordnungsgemäßen Stellplatz zu verbringen, eventuell so zu
            sichern, dass es ohne Mitwirkung des Parkflächenbetreibers vom Kunden nicht mehr weggefahren werden kann und die entstehenden
            Kosten zu verrechnen. Das Abschleppen erfolgt nach den geltenden kantonalen Vorschriften.
          </li>
        </ol>
        <li class="font-bold text-lg mb-3">Ordnungsvorschriften</li>
        <ol>
          <li>
            Fahrzeuge, die in den Betriebsstandort eingebracht werden, müssen verkehrs- und betriebssicher und zum Verkehr zugelassen sein.
            Jede Entfernung von Kennzeichentafeln, z.B. zum Zwecke der Ummeldung, ist nur mit schriftlicher Zustimmung des
            Parkflächenbetreibers zulässig.
          </li>
          <li>
            Verboten sind insbesondere:
            <ul>
              <li>das Rauchen sowie die Verwendung von Feuerund offenem Licht;</li>
              <li>das Abstellen und die Lagerung von Gegenständen aller Art, insbesondere von brennbaren und explosiven Stoffen;</li>
              <li>
                Wartungs-, Pflege- und Reparaturarbeiten wie insbesondere das Betanken von Fahrzeugen, Aufladung von Starterbatterien sowie
                das Ablassen des Kühlwassers;
              </li>
              <li>das längere Laufen lassen und das Ausprobieren des Motors und das Hupen;</li>
              <li>
                die Einstellung eines Fahrzeuges mit undichtem Betriebssystem (insbesondere bei Austritt von Treibstoff, Öl oder sonstige
                Flüssigkeiten) oder anderen, insbesondere sicherheitsrelevanten Mängeln, sowie die Einstellung solcher Fahrzeuge, die den
                verkehrstechnischen Vorschriften nicht entsprechen (z.B. ungültige oder abgelaufene Überprüfungsplakette);
              </li>
              <li>
                ohne Zustimmung vom Parkfächenbetreiber das Abstellen von Fahrzeugen ohne polizeiliches Kennzeichen oder ohne Anbringung
                eines Ersatzkennzeichens;
              </li>
              <li>
                das Abstellen des Fahrzeuges auf den Fahrstreifen, vor Notausgängen, auf Fußgängerwegen, vor Türen (Toren) und Ausgängen, im
                Bewegungsbereich von Türen und Toren;
              </li>
              <li>das Verteilen von Werbematerial ohne schriftliche Zustimmung des Parkflächenbetreibers;</li>
              <li>das Befahren der Parkfläche mit Fahrrad, Skateboard, Roller, Inlineskates und dgl.;</li>
            </ul>
          </li>
        </ol>
        <li class="font-bold text-lg mb-3">Zurückbehaltungsrecht</li>
        <ol>
          <li>
            Zur Sicherung der aller entstandener Forderungen gegenüber dem Kunden steht dem Parkflächenbetreiber ein Zurückbehaltungsrecht
            am eingebrachten Fahrzeug zu, selbst dann, wenn das Fahrzeug nicht dem Kunden, sondern einem Dritten gehört.
          </li>
          <li>
            Zur Sicherung des Zurückbehaltungsrechtes kann der Parkflächenbetreiber durch geeignete Mittel die Entfernung des Fahrzeuges
            verhindern. Die Ausübung des Zurückbehaltungsrechtes kann durch eine Sicherheitsleistung abgewendet werden.
          </li>
        </ol>
        <li class="font-bold text-lg mb-3">Verhalten im Brandfall</li>
        <ol>
          <li>
            Bei Brand oder Brandgeruch ist die Feuerwehr zu verständigen und allenfalls vorhandene Alarmierungseinrichtungen auszulösen. Die
            Meldung hat folgende Angaben zu enthalten: WO brennt es (Adresse, Zufahrtswege), WAS brennt (Gebäude, Auto), WIE viele Verletzte
            gibt es, WER ruft an (Name). Allfällig angebrachte Hinweisschilder „Verhalten im Brandfall“ sind zu beachten.
          </li>
          <li>Sofern notwendig und möglich, sind gefährdete Personen zu warnen und Verletzte bzw. hilflose Personen zu evakuieren.</li>
          <li>
            Soweit es unter Beachtung der eigenen Sicherheit möglich ist, ist ein Löschversuch mit einem geeigneten Feuerlöscher zu
            unternehmen, andernfalls ist die Parkfläche auf schnellstem Wege zu Fuß zu verlassen.
          </li>
          <li>Aufzüge im Brandfall nicht benützen!</li>
        </ol>
        <li class="font-bold text-lg mb-3">Bildaufzeichnungen</li>
        <ol>
          <li>
            Der Betreiber setzt Bildaufzeichnungen für folgende Zwecke ein:
            <ul>
              <li>Verwendung des KFZ-Kennzeichens als Parkberechtigungsmedium bei der Ein- und Ausfahrt (visuell bzw. automatisiert)</li>
              <li>zum Schutz der betriebenen Parkfläche bzw. zur Einhaltung von Sorgfaltspflichten</li>
            </ul>
          </li>
          <li>Die Einhaltung der gesetzlichen Bestimmungen wird gewährleistet.</li>
          <li>
            Die Bildaufzeichnungen dienen insbesondere nicht der Bewachung des Fahrzeuges und begründen keine Haftung des
            Parkflächenbetreibers.
          </li>
        </ol>
        <li class="font-bold text-lg mb-3">Datenschutz</li>
        <ol>
          <li>
            Der Betreiber verarbeitet zum Zwecke der Vertragserfüllung die dafür erforderlichen personenbezogenen Daten der Kunden. Nähere
            Informationen zur Datenverarbeitung enthält die Datenschutzerklärung, welche im Zuge des Abschlusses eines Nutzungsvertrages zur
            Verfügung gestellt wird.
          </li>
        </ol>
      </ol>
    </div>
  </div>
</div>
