import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { ProviderLinkOptions } from '../../models/providerSettings';
import { ProviderSettingsService } from '../../../core/services/provider-settings.service';
import { StorageManagerService } from '../../../core/services/storage-manager.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'arivo-legal-links',
  templateUrl: './arivo-legal-links.component.html',
  styleUrl: './arivo-legal-links.component.scss',
})
export class ArivoLegalLinksComponent implements OnInit {
  providerLinks!: ProviderLinkOptions;
  destroyRef = inject(DestroyRef);

  constructor(
    private providerSettingsService: ProviderSettingsService,
    private storageManagerService: StorageManagerService,
  ) {}

  ngOnInit() {
    this.providerSettingsService.providerSettings.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.providerLinks = this.providerSettingsService.getLinks();
    });
  }

  isInternalLink(url: string): boolean {
    return url.startsWith('/');
  }

  hasToken(): boolean {
    return !!this.storageManagerService.load('token');
  }

  isSidebarI18nKey(key: string): boolean {
    return key.startsWith('Sidebar.');
  }

  getAdditionalLinks(): string[] {
    return Object.keys(this.providerLinks).filter(
      (key) => !['imprint', 'terms_and_conditions', 'privacy_policy', 'accessibility'].includes(key),
    );
  }
}
