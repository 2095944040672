<div class="card">
  <div class="card-header">
    <div class="headline-large mb-3">Erklärung zur Barrierefreiheit</div>
    <p>
      Gültig für: <a [href]="baseUrl">{{ baseUrl }}</a>
    </p>
    <p>Stand: 31.03.2025</p>
  </div>
  <div class="card-body">
    <div class="body-medium">
      <div class="mb-5">
        Die Arivo GmbH ist bemüht, die oben genannte Webseite im Einklang mit der Richtline (EU) 2016/2102 barrierefrei zugänglich zu
        machen.
      </div>
      <div class="mb-5">
        <div class="font-bold">Stand der Vereinbarkeit mit den Anforderungen</div>
        <div>
          Diese Website ist wegen der unten aufgeführten Unvereinbarkeiten und/oder Ausnahmen teilweise mit Konformitätsstufe AA der
          „Richtlinien für barrierefreie Webinhalte Web –
          <a href="https://www.w3.org/TR/WCAG21/" target="_blank" [innerText]="'WCAG 2.1'">WCAG 2.1</a>“ entsprechend der geltenden
          harmonisierten europäischen Norm „Europäischer Standard EN 301 549 V3.2.1 (2021-03)“ vereinbar.
        </div>
      </div>
      <div class="mb-5">
        <div class="font-bold">Unvereinbarkeiten:</div>
        <ul class="list-disc ml-5">
          <li>Das Kontrastverhältnis zwischen Hintergrund- und Vordergrundfarben ist nicht an allen Stellen ausreichend.</li>
          <li>An einigen Stellen ist die Schriftgröße zu klein.</li>
        </ul>
      </div>
      <div class="mb-5">
        <div class="font-bold">Erstellung dieser Erklärung zur Barrierefreiheit:</div>
        <div>
          <div>Diese Erklärung wurde am 31.03.2025 erstellt.</div>
          <div>Die Erklärung wurde auf Grundlage einer von Google Lighthouse durchgeführten Selbstbewertung erstellt.</div>
        </div>
      </div>
      <div class="mb-5">
        <div class="font-bold">Kontakt und Feedback:</div>
        <div>
          Sollten Ihnen weitere Barrieren auffallen oder benötigen Sie Informationen in einer anderen Form, können Sie uns kontaktieren.
        </div>
        <div class="my-3">
          <div><b class="mr-3">Kontaktstelle:</b> Arivo GmbH, Am Innovationspark 10, 8020 Graz</div>
          <div>
            <b class="mr-3">E-Mail:</b>
            <a class="font-normal" href="mailto:datenschutz@arivo.co" [innerText]="'datenschutz@arivo.co'">datenschutz&#64;arivo.co</a>
          </div>
        </div>
        <div>
          Falls Ihre Anfrage unbeantwortet bleibt oder Sie mit der erhaltenen Antwort unzufrieden sind, können Sie sich an die zuständige
          Durchsetzungsstelle Ihres EU-Mitgliedstaates wenden.
        </div>
      </div>
    </div>
  </div>
</div>
