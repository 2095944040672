import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProviderSettingsService } from '../../core/services/provider-settings.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent implements OnInit {
  constructor(
    private providerSettingsService: ProviderSettingsService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    const key = this.route.snapshot.paramMap.get('key');
    if (!key) {
      this.router.navigate(['/dashboard']);
      return;
    }

    const linkOptions = this.providerSettingsService.getLinks();
    const linkOption = linkOptions[key as keyof typeof linkOptions];
    if (linkOption) {
      if (linkOption.url.startsWith('http://') || linkOption.url.startsWith('https://')) {
        window.location.href = linkOption.url;
      } else {
        this.router.navigateByUrl(linkOption.url).catch(() => {
          this.router.navigate(['/dashboard']);
        });
      }
    } else {
      this.router.navigate(['/dashboard']);
    }
  }
}
