<div class="link pt-4 px-4 gap-2">
  @if (isInternalLink(providerLinks.privacy_policy.url)) {
    <a
      [routerLink]="providerLinks.privacy_policy.url"
      [title]="
        isSidebarI18nKey(providerLinks.privacy_policy.title)
          ? (providerLinks.privacy_policy.title | translate)
          : providerLinks.privacy_policy.title
      "
      [innerText]="
        isSidebarI18nKey(providerLinks.privacy_policy.title)
          ? (providerLinks.privacy_policy.title | translate)
          : providerLinks.privacy_policy.title
      "
      [target]="hasToken() ? '_self' : '_blank'"
    >
    </a>
  } @else {
    <a
      [href]="providerLinks.privacy_policy.url"
      [title]="providerLinks.privacy_policy.title"
      [innerText]="providerLinks.privacy_policy.title"
      target="_blank"
      rel="noopener noreferrer"
    >
    </a>
  }

  @if (isInternalLink(providerLinks.terms_and_conditions.url)) {
    <a
      [routerLink]="providerLinks.terms_and_conditions.url"
      [title]="
        isSidebarI18nKey(providerLinks.terms_and_conditions.title)
          ? (providerLinks.terms_and_conditions.title | translate)
          : providerLinks.terms_and_conditions.title
      "
      [innerText]="
        isSidebarI18nKey(providerLinks.terms_and_conditions.title)
          ? (providerLinks.terms_and_conditions.title | translate)
          : providerLinks.terms_and_conditions.title
      "
      [target]="hasToken() ? '_self' : '_blank'"
    >
    </a>
  } @else {
    <a
      [href]="providerLinks.terms_and_conditions.url"
      target="_blank"
      rel="noopener noreferrer"
      [title]="providerLinks.terms_and_conditions.title"
      [innerText]="providerLinks.terms_and_conditions.title"
    >
    </a>
  }

  @if (isInternalLink(providerLinks.imprint.url)) {
    <a
      [routerLink]="providerLinks.imprint.url"
      [title]="isSidebarI18nKey(providerLinks.imprint.title) ? (providerLinks.imprint.title | translate) : providerLinks.imprint.title"
      [innerText]="isSidebarI18nKey(providerLinks.imprint.title) ? (providerLinks.imprint.title | translate) : providerLinks.imprint.title"
      [target]="hasToken() ? '_self' : '_blank'"
    >
    </a>
  } @else {
    <a
      [href]="providerLinks.imprint.url"
      target="_blank"
      [title]="providerLinks.imprint.title"
      [innerText]="providerLinks.imprint.title"
      rel="noopener noreferrer"
    >
    </a>
  }

  @if (isInternalLink(providerLinks.accessibility.url)) {
    <a
      [routerLink]="providerLinks.accessibility.url"
      [title]="
        isSidebarI18nKey(providerLinks.accessibility.title)
          ? (providerLinks.accessibility.title | translate)
          : providerLinks.accessibility.title
      "
      [innerText]="
        isSidebarI18nKey(providerLinks.accessibility.title)
          ? (providerLinks.accessibility.title | translate)
          : providerLinks.accessibility.title
      "
      [target]="hasToken() ? '_self' : '_blank'"
    >
    </a>
  } @else {
    <a
      [href]="providerLinks.accessibility.url"
      target="_blank"
      [title]="providerLinks.accessibility.title"
      [innerText]="providerLinks.accessibility.title"
      rel="noopener noreferrer"
    >
    </a>
  }

  <ng-container *ngFor="let key of getAdditionalLinks()">
    <a
      [href]="providerLinks[key].url"
      [title]="providerLinks[key].title"
      [innerText]="providerLinks[key].title"
      target="_blank"
      rel="noopener noreferrer"
    ></a>
  </ng-container>
</div>
