<div class="headline-small mb-2" [innerText]="'Billing.AccountBalance' | translate"></div>

<div class="card card-small">
  <div class="card-body 2xl:mb-0.5">
    <div class="flex flex-wrap justify-between gap-4 2xl:my-1.5">
      <div>
        @for (openCost of openCosts; track $index) {
          <div class="headline-x-small 2xl:headline-medium mb-1" [innerText]="openCost.amount | localizedCurrency: openCost.currency"></div>
          <div class="flex flex-wrap body-small 2xl:body-medium my-0.5 2xl:my-1">
            <div class="mr-1" [innerText]="'Billing.OpenBalance:' | translate"></div>
            <div [innerText]="'Billing.ParkingBasedOnTime' | translate"></div>
          </div>
        } @empty {
          <div class="headline-x-small 2xl:headline-medium mb-1" [innerText]="0 | localizedCurrency: 'EUR'"></div>
          <div class="flex flex-wrap body-small 2xl:body-medium my-0.5 2xl:my-1">
            <div class="mr-1" [innerText]="'Billing.OpenBalance:' | translate"></div>
            <div [innerText]="'Billing.ParkingBasedOnTime' | translate"></div>
          </div>
        }
      </div>
      <div class="body-medium 2xl:body-large overflow-hidden text-left">
        <div class="mb-1 2xl:my-2.5">
          @if (paymentMethod) {
            @switch (paymentMethod.type) {
              @case ('scheme') {
                <div class="flex items-center gap-3 w-full h-6 md:h-8">
                  <div class="headline-medium 2xl:headline-large mb-1">
                    <i [ngClass]="creditCardIconClass"></i>
                  </div>
                  <div class="whitespace-nowrap flex items-center">
                    <div class="mr-1 text-neutral-70">****</div>
                    <div>{{ paymentMethod.card_summary }}</div>
                  </div>
                </div>
              }
              @case ('googlepay') {
                <div class="flex items-center gap-3 w-full h-6 md:h-8">
                  <i class="headline-medium 2xl:headline-large fa-brands fa-google-pay"></i>
                  @if (paymentMethod.card_summary) {
                    <div class="whitespace-nowrap flex items-center">
                      <div class="mr-1 text-neutral-70">****</div>
                      <div>{{ paymentMethod.card_summary }}</div>
                    </div>
                  }
                </div>
              }
              @case ('applepay') {
                <div class="flex items-center gap-3 w-full h-6 md:h-8">
                  <i class="headline-medium 2xl:headline-large fa-brands fa-apple-pay"></i>
                  @if (paymentMethod.card_summary) {
                    <div class="whitespace-nowrap flex items-center">
                      <div class="mr-1 text-neutral-70">****</div>
                      <div>{{ paymentMethod.card_summary }}</div>
                    </div>
                  }
                </div>
              }
              @case ('sepadirectdebit') {
                <div class="flex items-center gap-3 w-full h-6 md:h-8">
                  <i class="headline-medium 2xl:headline-large fa-light fa-money-bill-transfer"></i>
                  @if (paymentMethod.card_summary) {
                    <div class="whitespace-nowrap flex items-center">
                      <div class="mr-1">SEPA</div>
                      <div class="mr-1 text-neutral-70">****</div>
                      <div>{{ paymentMethod.card_summary }}</div>
                    </div>
                  } @else {
                    <p class="truncate" dir="rtl">SEPA</p>
                  }
                </div>
              }
            }
          } @else {
            <a
              class="inline-flex items-center text-decoration-none mt-0.5 mb-1 md:my-1.5 cursor-pointer"
              (click)="paymentContainer.setUpComponents()"
            >
              <span [innerText]="'Dashboard.AddNow' | translate"></span>
              <i class="fa-light fa-chevron-right ml-1.5"></i>
            </a>
          }
        </div>
        <div>
          <div class="body-small 2xl:body-medium">
            <span [class.text-warning]="!paymentMethod" [innerText]="'Dashboard.PaymentMethod' | translate"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

@if (paymentMethod) {
  <div class="text-right body-medium mt-2">
    <a
      class="inline-flex select-none text-decoration-none my-0 md:my-1 cursor-pointer items-center"
      (click)="paymentContainer.setUpComponents()"
    >
      <span class="ml-auto" [innerText]="'Billing.ChangePaymentMethod' | translate"></span>
      <i class="fa-light fa-chevron-right ml-1.5"></i>
    </a>
  </div>
}

<arivo-bottom-sheet-dialog #billingPaymentMethodDialog (onClose)="paymentContainer.unmountComponents()">
  <div class="flex justify-between mb-5">
    <div class="headline-x-small" [innerText]="'Payment.SelectPaymentMethod' | translate"></div>
    <div class="cursor-pointer text-xl" (click)="billingPaymentMethodDialog.close()">
      <i class="fa-light fa-xmark-large"></i>
    </div>
  </div>
  @if (!adyenIframeEnabled) {
    <arivo-payment-container
      #paymentContainer
      (submitted)="billingPaymentMethodDialog.close()"
      (failed)="billingPaymentMethodDialog.close()"
      (initialized)="billingPaymentMethodDialog.open = true"
    ></arivo-payment-container>
  } @else {
    <app-arivo-payment-iframe
      #paymentContainer
      [autoInit]="autoInitPaymentForm"
      (submitted)="billingPaymentMethodDialog.close()"
      (failed)="billingPaymentMethodDialog.close()"
      (initialized)="billingPaymentMethodDialog.open = true"
    ></app-arivo-payment-iframe>
  }
</arivo-bottom-sheet-dialog>
