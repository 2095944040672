import { Component, Input, OnInit } from '@angular/core';
import { Garage } from '../../../shared/models/garage';
import { FeatureFlagService } from '../../../core/services/feature-flag.service';

@Component({
  selector: 'app-garage-details',
  templateUrl: './garage-details.component.html',
  styleUrl: './garage-details.component.scss',
})
export class GarageDetailsComponent implements OnInit {
  @Input() garage?: Garage;

  showWiparkShortTermParking: boolean = this.featureFlagService.getFeatureFlag('translation_key') === 'wipark';
  creatingContractEnabled!: boolean;

  constructor(private featureFlagService: FeatureFlagService) {}

  ngOnInit(): void {
    this.creatingContractEnabled = this.featureFlagService.getFeatureFlag('enable_contract_creation_products');
  }

  get bookableContractTemplates() {
    return this.garage?.contract_templates?.filter((template) => template.bookable === 'public') || [];
  }

  get informationContractTemplates() {
    return this.garage?.contract_templates?.filter((template) => template.bookable === 'information') || [];
  }

  formatAddress(garage: Garage): string {
    return `${garage.street} ${garage.number}, ${garage.zip_code} ${garage.city}`;
  }
}
