import { Component, DestroyRef, forwardRef, inject, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ProviderLinkOptions } from '../../models/providerSettings';
import { ProviderSettingsService } from '../../../core/services/provider-settings.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'arivo-marketing-consent',
  templateUrl: './arivo-marketing-consent.component.html',
  styleUrl: './arivo-marketing-consent.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ArivoMarketingConsentComponent),
      multi: true,
    },
  ],
})
export class ArivoMarketingConsentComponent implements ControlValueAccessor, OnInit {
  destroyRef = inject(DestroyRef);
  providerLinks!: ProviderLinkOptions;

  constructor(private providerSettingsService: ProviderSettingsService) {}

  ngOnInit() {
    this.providerSettingsService.providerSettings.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.providerLinks = this.providerSettingsService.getLinks();
    });
  }

  public regOnChange = (_: any) => {};

  public regOnTouched = (_: any) => {};

  registerOnChange(fn: any): void {
    this.regOnChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.regOnTouched = fn;
  }

  writeValue(_: any) {}

  onChange($event: any) {
    this.regOnChange($event.target.checked);
  }
}
