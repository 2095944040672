<div class="card" *ngIf="providerData">
  <div class="card-header">
    <div class="headline-large" [innerText]="'DefaultImprint.Header' | translate"></div>
  </div>
  <div class="card-body">
    <div class="headline-small">{{ providerData.name }}</div>
    <hr class="my-4" />
    <div class="body-medium">
      <div *ngIf="providerData.representative" class="mb-2">
        <span class="font-bold text-decoration mr-2" [innerText]="'DefaultImprint.Owner' | translate"></span>
        {{ providerData.representative }}
      </div>
      <div class="mb-2">
        <span class="font-bold text-decoration mr-2" [innerText]="'DefaultImprint.Address' | translate"></span>
        {{ providerData.address }}, {{ providerData.zip_code }} {{ providerData.city }}, {{ providerData.country }}
      </div>
      <div *ngIf="providerData.contact_phone_number" class="mb-2">
        <span class="font-bold text-decoration mr-2" [innerText]="'DefaultImprint.Phone' | translate"></span>
        <a class="hover:text-interaction-light text-interaction no-underline" [href]="'tel:' + providerData.contact_phone_number">{{
          providerData.contact_phone_number
        }}</a>
      </div>
      <div *ngIf="providerData.contact_email">
        <span class="font-bold text-decoration mr-2" [innerText]="'DefaultImprint.Email' | translate"></span>
        <a class="hover:text-interaction-light text-interaction no-underline" [href]="'mailto:' + providerData.contact_email">{{
          providerData.contact_email
        }}</a>
      </div>
      <hr class="my-4" />
      <div *ngIf="providerData.vat_id" class="mb-2">
        <span class="font-bold text-decoration mr-2" [innerText]="'DefaultImprint.UID' | translate"></span> {{ providerData.vat_id }}
      </div>
      <div class="mb-2">
        @if (isGermany(providerData)) {
          <span class="font-bold text-decoration mr-2" [innerText]="'DefaultImprint.Jurisdiction.Germany' | translate"></span>
        } @else {
          <span class="font-bold text-decoration mr-2" [innerText]="'DefaultImprint.Jurisdiction.Austria' | translate"></span>
        }
        {{ providerData.jurisdiction }}
      </div>
      <div>
        @if (isGermany(providerData)) {
          <span class="font-bold text-decoration mr-2" [innerText]="'DefaultImprint.RegisterNumber.Germany' | translate"></span>
        } @else {
          <span class="font-bold text-decoration mr-2" [innerText]="'DefaultImprint.RegisterNumber.Austria' | translate"></span>
        }
        {{ providerData.register_number }}
      </div>
      <hr class="my-4" />
      <div class="mb-2">
        <span [innerText]="'DefaultImprint.ConsumerComplaint' | translate"></span>
        <a class="hover:text-interaction-light text-interaction no-underline" href="http://ec.europa.eu/odr"> http://ec.europa.eu/odr</a>.
      </div>
      <div class="mb-2">
        <span class="mr-2" [innerText]="'DefaultImprint.ComplaintEmail' | translate"></span>
        <a class="hover:text-interaction-light text-interaction no-underline" [href]="'mailto:' + providerData.contact_email">{{
          providerData.contact_email
        }}</a>
      </div>
    </div>
  </div>
</div>
