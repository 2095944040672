import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PaymentMethod } from '@adyen/adyen-web';
import { ArivoPaymentMethod } from '../../models/common';
import { CoreDataService } from '../../../core/services/core-data.service';

@Injectable({
  providedIn: 'root',
})
export class ArivoPaymentService {
  activePaymentMethod = new BehaviorSubject<ArivoPaymentMethod | null>(null);

  constructor(
    private http: HttpClient,
    private coreDataService: CoreDataService,
  ) {}

  setActivePaymentMethod(paymentMethod: ArivoPaymentMethod): void {
    this.activePaymentMethod.next(paymentMethod);
    this.coreDataService.setPaymentMethodAvailability(!!paymentMethod, true);
  }

  getPaymentMethods(): Observable<{ payment_methods: PaymentMethod[]; client_key: string }> {
    return this.http
      .get<{ payment_methods: PaymentMethod[]; client_key: string }>('/api/invoice/v2/payment_method/available_payment_methods/', {})
      .pipe(
        map((response) => {
          response.payment_methods = this.prioritizeSepaDirectDebit(response.payment_methods);
          return response;
        }),
      );
  }

  submitAdditionalDetails(state: any): Observable<PaymentResponse> {
    return this.http.post<PaymentResponse>('/api/invoice/v2/payment_method/submit_authentication_details/', { adyen_data: state.data });
  }

  initiatePayment(state: any, returnUrl: string = '/dashboard'): Observable<any> {
    return this.http.post('/api/invoice/v2/payment_method/', {
      adyen_data: state.data,
      return_url: returnUrl,
    });
  }

  prioritizeSepaDirectDebit(paymentMethods: PaymentMethod[]): PaymentMethod[] {
    const sepaIndex = paymentMethods.findIndex((method) => method.type === 'sepadirectdebit');
    if (sepaIndex > -1) {
      const [sepaMethod] = paymentMethods.splice(sepaIndex, 1);
      paymentMethods.unshift(sepaMethod);
    }
    return paymentMethods;
  }

  getCreditCardIcon(brand: string | undefined): string {
    if (!brand) {
      return 'fa-light fa-credit-card';
    }
    switch (brand) {
      case 'visa':
        return 'fa-brands fa-cc-visa';
      case 'mc':
        return 'fa-brands fa-cc-mastercard';
      case 'amex':
        return 'fa-brands fa-cc-amex';
      case 'diners':
        return 'fa-brands fa-cc-diners-club';
      case 'discover':
        return 'fa-brands fa-cc-discover';
      case 'jcb':
        return 'fa-brands fa-cc-jcb';
      default:
        return 'fa-light fa-credit-card';
    }
  }
}
