import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { AssetHashesService } from '../services/asset-hashes.service';
import { ProviderSettingsService } from '../services/provider-settings.service';
import * as Sentry from '@sentry/angular';

function handleAssetHashesChanges(
  assetHashesService: AssetHashesService,
  httpClient: HttpClient,
  providerSettingsService: ProviderSettingsService,
  lang: string,
  subscriber: any,
): void {
  let isAssetHashesChangesSubscriptionExecuted: boolean = false;
  const assetHashesChangesSubscription: Subscription = assetHashesService.assetHashesChanges().subscribe({
    next: (assetHashes) => {
      if (!assetHashes || isAssetHashesChangesSubscriptionExecuted) {
        return;
      }

      isAssetHashesChangesSubscriptionExecuted = true;
      Promise.resolve().then(() => assetHashesChangesSubscription.unsubscribe());

      const allowedTranslationKeys = ['default', 'wipark'];

      let langAssetUrl: string = '';
      const translationKey = providerSettingsService.getTranslationKey();

      if (!allowedTranslationKeys.includes(translationKey)) {
        console.warn(`Translation key ${translationKey} is not allowed!`);
        Sentry.captureEvent({ message: `Translation key ${translationKey} is not allowed!`, level: 'warning' });
      }

      if (translationKey === 'default' || !allowedTranslationKeys.includes(translationKey)) {
        langAssetUrl = `assets/i18n/${lang}.json`;
      } else {
        langAssetUrl = `assets/i18n/merged/${translationKey}.${lang}.json`;
      }

      httpClient.get(assetHashesService.getHashedAssetUrl(langAssetUrl)).subscribe({
        next: (res: any) => {
          subscriber.next(res);
          subscriber.complete();
        },
        error: (err: any) => {
          subscriber.error(err);
          subscriber.complete();
        },
      });
    },
  });
}

export const createTranslateLoader = (
  httpClient: HttpClient,
  assetHashesService: AssetHashesService,
  providerSettingsService: ProviderSettingsService,
): TranslateLoader => ({
  getTranslation: (lang: string) => {
    return new Observable<string>((subscriber) => {
      handleAssetHashesChanges(assetHashesService, httpClient, providerSettingsService, lang, subscriber);
    });
  },
});
